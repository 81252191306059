import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import HomePage from "./pages/home/index";

import AuthUserPage from "./pages/account/auth/index";
import AuthPreviewPage from "./pages/account/auth/preview";
import AuthConfirmPage from "./pages/account/auth/confirm";
import AuthOtpPage from "./pages/account/auth/otp";
import RegisterUserPage from "./pages/account/register/index";

import WelcomePage from "./pages/account/welcome/index";
import ProfilePage from "./pages/account/profile/index";
import EditProfilePage from "./pages/account/profile/edit/index";
import EditProfileOtpPage from "./pages/account/profile/edit/otp";
import TierPage from "./pages/account/profile/tier";

import EarnPointHistoryPage from "./pages/account/history/point/earn";
import BurnPointHistoryPage from "./pages/account/history/point/burn";

import TransactionHistoryPage from "./pages/account/history/transaction";
import BuyTransactionHistoryPage from "./pages/account/history/transaction/buy";
import BuyTransactionHistoryDetailPage from "./pages/account/history/transaction/buy/detail";
import ReturnTransactionHistoryPage from "./pages/account/history/transaction/return";
import ReturnTransactionHistoryDetailPage from "./pages/account/history/transaction/return/detail";

import CouponPage from "./pages/account/promotion/coupon";
import CouponDetailPage from "./pages/account/promotion/coupon/detail";
import CouponRedeemPage from "./pages/account/promotion/coupon/redeem";
import CouponHistoryPage from "./pages/account/promotion/coupon/history";
import CouponHistoryDetailPage from "./pages/account/promotion/coupon/historyDetail";
import CouponHistoryRedeemPage from "./pages/account/promotion/coupon/historyRedeem";
import PromotionPage from "./pages/account/promotion";
import PromotionDetailPage from "./pages/account/promotion/detail";
import PromotionImagePage from "./pages/account/promotion/image";

import PrivacyPolicyPage from "./pages/consent/privacyPolicy";

import TelepharmacyPage from "./pages/telepharmacy/index";
import BranchListPage from "./pages/branch/index";
import BranchMapPage from "./pages/branch/map";

import DetailBonePage from "./pages/account/healthservice/detail/bone";
import DetailFatPage from "./pages/account/healthservice/detail/fat";
import DetailMusclePage from "./pages/account/healthservice/detail/muscle";
import DetailPressurePage from "./pages/account/healthservice/detail/pressure";
import DetailSugarPage from "./pages/account/healthservice/detail/sugar";
import DetailVisceralfatPage from "./pages/account/healthservice/detail/visceralfat";
import HealthServiceDetailPage from "./pages/account/healthservice/detail";
import HealthServiceMainPage from "./pages/account/healthservice/main";
import HealthServiceCatagory from "./pages/account/healthservice/catagory";
import HealthServiceUpdate from "./pages/account/healthservice/update";
import HealthServiceHistoryPage from "./pages/account/healthservice/history";
import HealthServiceHistoryGraphPage from "./pages/account/healthservice/history/graph";

import SurveyList from "./pages/account/survey";
import SurveyForm from "./pages/account/survey/form";
import SurveyHistory from "./pages/account/survey/history";
import SurveyComplete from "./pages/account/survey/complete";

const appRouter = (props) => {
  // console.log(props.isHasProfile, props.profileData);

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route
            path="/"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <HomePage />
            }
          />

          {/* <Route path="/home" element={<HomePage />} /> */}
          <Route
            path="/home"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <HomePage />
            }
          />

          {/* <Route path="/auth-user" element={<AuthUserPage />} /> */}
          <Route
            path="/auth-user"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <AuthUserPage />
            }
          />

          {/* <Route path="/auth-preview" element={<AuthPreviewPage />} /> */}
          <Route
            path="/auth-preview"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <AuthPreviewPage />
              )
            }
          />

          {/* <Route path="/auth-confirm" element={<AuthConfirmPage />} /> */}
          <Route
            path="/auth-confirm"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <AuthConfirmPage />
              )
            }
          />

          {/* <Route path="/register" element={<RegisterUserPage />} /> */}
          <Route
            path="/register"
            element={
              props.isHasProfile ? (
                <Navigate to="/profile" />
              ) : (
                <RegisterUserPage />
              )
            }
          />

          {/* <Route path="/auth-otp" element={<AuthOtpPage />} /> */}
          <Route
            path="/auth-otp"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <AuthOtpPage />
            }
          />

          <Route path="/welcome" element={<WelcomePage />} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/tier" element={<TierPage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />
          <Route path="/edit-profile/otp" element={<EditProfileOtpPage />} />

          <Route
            path="/earn-point-history"
            element={<EarnPointHistoryPage />}
          />
          <Route
            path="/burn-point-history"
            element={<BurnPointHistoryPage />}
          />

          <Route
            path="/transaction-history"
            element={<TransactionHistoryPage />}
          />
          <Route
            path="/buy-transaction-history"
            element={<BuyTransactionHistoryPage />}
          />
          <Route
            path="/buy-transaction-history/detail"
            element={<BuyTransactionHistoryDetailPage />}
          />
          <Route
            path="/return-transaction-history"
            element={<ReturnTransactionHistoryPage />}
          />
          <Route
            path="/return-transaction-history/detail"
            element={<ReturnTransactionHistoryDetailPage />}
          />

          <Route path="/coupon" element={<CouponPage />} />
          <Route path="/coupon/detail" element={<CouponDetailPage />} />
          <Route path="/coupon/redeem" element={<CouponRedeemPage />} />
          <Route path="/coupon/history" element={<CouponHistoryPage />} />
          <Route
            path="/coupon/history/detail"
            element={<CouponHistoryDetailPage />}
          />
          <Route
            path="/coupon/history/redeem"
            element={<CouponHistoryRedeemPage />}
          />

          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/promotion/detail" element={<PromotionDetailPage />} />
          <Route path="/promotion/image" element={<PromotionImagePage />} />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* <Route path="/telepharmacy" element={<TelepharmacyPage />} /> */}
          <Route
            path="/telepharmacy"
            element={
              props.isHasProfile ? <TelepharmacyPage /> : <Navigate to="/" />
            }
          />

          <Route path="/branch" element={<BranchListPage />} />
          <Route path="/branch/map" element={<BranchMapPage />} />

          {/* healthservice detail =  ค่า BMI */}
          <Route
            path="/healthservice-detail"
            element={<HealthServiceDetailPage />}
          />
          <Route path="/detail/bone" element={<DetailBonePage />} />
          <Route path="/detail/fat" element={<DetailFatPage />} />
          <Route path="/detail/muscle" element={<DetailMusclePage />} />
          <Route path="/detail/pressure" element={<DetailPressurePage />} />
          <Route path="/detail/sugar" element={<DetailSugarPage />} />
          <Route
            path="/detail/visceralfat"
            element={<DetailVisceralfatPage />}
          />
          <Route
            path="/healthservice-main"
            element={<HealthServiceMainPage />}
          />
          <Route
            path="/healthservice-catagory"
            element={<HealthServiceCatagory />}
          />
          <Route
            path="/healthservice-update"
            element={<HealthServiceUpdate />}
          />
          <Route
            path="/healthservice-history"
            element={<HealthServiceHistoryPage />}
          />
          <Route
            path="/healthservice-history-graph"
            element={<HealthServiceHistoryGraphPage />}
          />

          <Route path="/survey" element={<SurveyList />} />
          <Route path="/survey/form" element={<SurveyForm />} />
          <Route path="/survey/history" element={<SurveyHistory />} />
          <Route path="/survey/complete" element={<SurveyComplete />} />

          {/* Not found 404 */}
          <Route
            path="*"
            exact={true}
            // element={<Navigate replace to="/404" />}
            element={<Navigate replace to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default appRouter;
