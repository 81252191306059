import { useNavigate } from "react-router-dom";

import img_icoMember from "../../assets/img/ci/icon-menu/ico-profile.svg";
import img_icoHistory from "../../assets/img/ci/icon-menu/ico-history.svg";
import img_icoCoupon from "../../assets/img/ci/icon-menu/ico-promotion.svg";
import img_icoSurvey from "../../assets/img/ci/icon-menu/ico-survey.svg";

// import useTranslations from "../../i18n/useTranslations";

const MenuMember = (props) => {
  // const { t } = useTranslations();

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  return (
    <>
      <section id="pActionMenu">
        <div className="bBtn">
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="profile"
            className={props.pageActive === "profile" ? "active" : ""}
          >
            <i>
              <img src={img_icoMember} alt="" />
            </i>
            <span>โปรไฟล์</span>
          </a>
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="transaction-history"
            className={props.pageActive === "history" ? "active" : ""}
          >
            <i>
              <img src={img_icoHistory} alt="" />
            </i>
            <span>ประวัติการซื้อสินค้า</span>
          </a>
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="survey"
            className={props.pageActive === "survey" ? "active" : ""}
          >
            <i>
              <img src={img_icoSurvey} />
              <b className="noti">9</b>
            </i>
            <span>แบบสอบถาม</span>
            <p>จะได้รับ 60 pts</p>
          </a>

          <a
            href="#"
            onClick={handleGotoPage}
            data-page="coupon"
            className={props.pageActive === "promotion" ? "active" : ""}
          >
            <i>
              <img src={img_icoCoupon} alt="" />
            </i>
            <span>โปรโมชั่น</span>
          </a>
        </div>
      </section>
    </>
  );
};

export default MenuMember;
