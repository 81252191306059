import React from "react";

const HeaderHealthService = () => {
  return (
    <header>
      <div className="bHealthService">
        <img src="./assets/img/ci/img-healthservice.png" />
      </div>
    </header>
  );
};

export default HeaderHealthService;
