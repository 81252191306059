import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { checkDupMobileNo } from "../../../services/Api/Module/Customer";

import PageTitleGuest from "../../../layouts/inc/PageTitleGuest";

import ModalContext from "../../../contexts/ModalContext";

import Helper, {
  // convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

import moment from "moment";

import img_icoCheckUser from "../../../assets/img/ci/icon-register/ico-checkUser.svg";

const AuthPreview = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-preview");

  const PageRoute = {
    "auth-user": "/auth-user",
    "auth-confirm": "/auth-confirm",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      mobileNo: "",
      firstname: "",
      lastname: "",
      gender: "",
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          handleCheckMobileNoExists(_value);
        } else {
          _invalid = true;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Check mobile nubmer exists real-time on server-side  */
  const handleCheckMobileNoExists = (mobileNo) => {
    // console.log(mobileNo);

    clearErrors("mobileNo");

    getCheckDupMobileNo({
      mobileNo: mobileNo,
      id: stateRegister.dataUser.id,
      // custCode: stateRegister.dataUser.custCode,
    }).then((_rsData) => {
      // console.log(_rsData);
      // if (_rsData.status === true) {
      //
      // } else {
      //
      // }
    });
  };

  const getCheckDupMobileNo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await checkDupMobileNo({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCheckDupMobileNo resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        if (data.resultCode === 40301) {
          setFormIsValid(false);

          setError(
            "mobileNo",
            {
              type: "manual",
              message: `เบอร์โทรศัพท์นี้มีผู้ใช้งานแล้ว`,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          clearErrors("mobileNo");
        }
      }
    } catch (e) {
      console.log("ERR getCheckDupMobileNo >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exists real-time on server-side  */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    setDataRegis({
      ...stateRegister.dataUser,
      ...data,
    });

    goNext("auth-confirm");
  };

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("firstname", dataUser.firstname);
      setValue("lastname", dataUser.lastname);
      setValue("gender", dataUser.gender);

      let _chkFormIsValid = true;

      const _chkField = {
        mobileNo: dataUser.mobileNo,
        firstname: dataUser.firstname,
        lastname: dataUser.lastname,
        gender: dataUser.gender,
      };

      Object.entries(_chkField).forEach(([key, val]) => {
        if (val === "" || val === false || val === null) {
          _chkFormIsValid = false;
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegisterResult-check">
        <main>
          <div className="wrapContent">
            <PageTitleGuest
              step={{
                current: 2,
                max: 3,
              }}
            />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister">
                  <h3>
                    <img src={img_icoCheckUser} alt="" />
                    ตรวจสอบข้อมูลส่วนตัว
                  </h3>
                  <div className="bForm">
                    <div className="bRow">
                      <div className="bText">
                        รหัสสมาชิก
                        <span className="text">
                          {stateRegister.dataUser.custCode}
                        </span>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="bText">
                        วัน/เดือน/ปีเกิด
                        <span className="text">
                          {/* {stateRegister.dataUser.birthDate
                            ? convertDateFormat(
                                stateRegister.dataUser.birthDate,
                                "d/m/y"
                              )
                            : ""} */}
                          {stateRegister.dataUser.birthDate &&
                            moment(stateRegister.dataUser.birthDate).format(
                              "DD/MM/"
                            ) +
                              (parseInt(
                                moment(stateRegister.dataUser.birthDate).format(
                                  "YYYY"
                                )
                              ) +
                                543)}
                        </span>
                      </div>
                    </div>

                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกเบอร์โทรศัพท์`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.firstname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ชื่อ</div>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อ`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกชื่อ`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.firstname && (
                          <label htmlFor="" className="tError">
                            {errors.firstname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.lastname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">นามสกุล</div>
                        <Controller
                          name="lastname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกนามสกุล`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกนามสกุล`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.lastname && (
                          <label htmlFor="" className="tError">
                            {errors.lastname.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div
                      className={`control-group margine-button ${
                        errors.gender ? "error" : ""
                      }`}
                    >
                      <div className="tTitle">เพศ</div>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: `กรุณาเลือกเพศ`,
                        }}
                        render={({ field }) => (
                          <div className="control-radio">
                            <label className="control control--radio">
                              ชาย
                              <input
                                {...field}
                                type="radio"
                                value="m"
                                checked={field.value === "m"}
                              />
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">
                              หญิง
                              <input
                                {...field}
                                type="radio"
                                value="f"
                                checked={field.value === "f"}
                              />
                              <div className="control__indicator"></div>
                            </label>
                          </div>
                        )}
                      />
                      {errors.gender && (
                        <label htmlFor="" className="tError">
                          {errors.gender.message}
                        </label>
                      )}
                    </div>

                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                  <div className="bRow">
                    <p className="remark">
                      *หากข้อมูลไม่ถูกต้อง กรุณาติดต่อ Health up
                      <br />
                      เบอร์ 027383030 ต่อ 602-603
                    </p>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("auth-user");
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn btnInfoConfirm"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthPreview;
