import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon, resetCoupon } from "../../../../features/CouponSlice";

import moment from "moment";

import img_icoTicket from "../../../../assets/img/ci/ico-coupon/ico-ticket.svg";

const CouponHistoryDetail = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  const setDataCoupon = (redeemData) => {
    const tmpObj = { redeem: redeemData };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataCoupon = () => {
    dispatch(resetCoupon()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataCoupon();

    navigate("/coupon/history");
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", stateProfile, stateCoupon);

    if (
      stateCoupon.id === 0 &&
      Object.getOwnPropertyNames(stateCoupon.data).length === 0
    ) {
      navigate("/profile");
    }
  }, []);
  /* End First event render */

  /* Check image exists */
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = stateCoupon.data.thumbnailImg;

    img.onload = () => {
      setImageExists(true);
    };

    // img.onerror = () => {
    //   setImageExists(false);
    // };
  }, [stateCoupon.data.thumbnailImg]);
  /* End Check image exists */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCoupon-Use">
        <main>
          <div className="wrapContent">
            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bHead">
                  <div className="bTitle">
                    <h1>
                      {stateCoupon.redeem.isUsed === 1
                        ? "คูปองถูกใช้แล้ว"
                        : stateCoupon.redeem.isUsed === 0 &&
                          moment(new Date()).format() >
                            moment(stateCoupon.redeem.expiredAt).format()
                        ? "คูปองหมดอายุแล้ว"
                        : ""}
                    </h1>
                    <p>
                      <span>
                        {stateCoupon.data.name}
                        <br />
                        สำหรับยอดซื้อ{" "}
                        {parseInt(stateCoupon.data.minTotal).toLocaleString()}
                        .-
                      </span>
                    </p>
                  </div>
                  <div className="bPic">
                    {imageExists ? (
                      <img src={stateCoupon.data.thumbnailImg} />
                    ) : (
                      <img src={img_icoTicket} />
                    )}
                  </div>
                </div>
                <div className="bDetails">
                  <h2>รายละเอียด</h2>
                  <div className="bRow">
                    <p>รหัสคูปอง:</p>
                    <p>{stateCoupon.redeem.code}</p>
                  </div>
                  <div className="bRow">
                    <p>วันที่/เวลาแลก:</p>
                    {/* <p>
                      {moment(stateCoupon.redeem.createdOn).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}{" "}
                      น.
                    </p> */}
                    <p>
                      {moment(stateCoupon.redeem.createdOn).format("DD/MM/") +
                        (parseInt(
                          moment(stateCoupon.redeem.createdOn).format("YYYY")
                        ) +
                          543) +
                        " " +
                        moment(stateCoupon.redeem.createdOn).format(
                          "HH:mm:ss"
                        )}{" "}
                      น.
                    </p>
                  </div>
                  <div className="bRow">
                    <p>คะแนนที่ใช้:</p>
                    <p>{stateCoupon.data.point} คะแนน</p>
                  </div>
                  <div className="bRow">
                    <p>สาขาที่ใช้งาน:</p>
                    <p>
                      {stateCoupon.redeem.branchName !== null
                        ? stateCoupon.redeem.branchName
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponHistoryDetail;
