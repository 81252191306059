import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon } from "../../../../features/CouponSlice";

import moment from "moment";

import img_icoTicket from "../../../../assets/img/ci/ico-coupon/ico-ticket.svg";

const CardCouponHistory = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataCoupon = (id, data, redeemData = {}) => {
    const tmpObj = { id: id, data: data, redeem: redeemData };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto coupon detail page */
  const navigate = useNavigate();

  const handleGotoCouponHistoryDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoCouponDetail >>", props.dataCoupon);

    const _timeLeft = moment(props.dataCoupon.redeemExpiredAt).diff(
      moment(new Date()),
      "seconds"
    );
    const _expiredInMin = moment.duration(_timeLeft, "seconds").minutes();
    const _expiredInSec = moment.duration(_timeLeft, "seconds").seconds();

    setDataCoupon(
      props.dataCoupon.id,
      {
        id: props.dataCoupon.id,
        createdOn: props.dataCoupon.createdOn,
        updatedOn: props.dataCoupon.updatedOn,
        cash: props.dataCoupon.cash,
        conditions: props.dataCoupon.conditions,
        couponAmount: props.dataCoupon.couponAmount,
        couponLimit: props.dataCoupon.couponLimit,
        custLimit: props.dataCoupon.custLimit,
        description: props.dataCoupon.description,
        discFix: props.dataCoupon.discFix,
        discPercent: props.dataCoupon.discPercent,
        endDate: props.dataCoupon.endDate,
        expiredTime: props.dataCoupon.expiredTime,
        isApplyAnother: props.dataCoupon.isApplyAnother,
        minTotal: props.dataCoupon.minTotal,
        name: props.dataCoupon.name,
        point: props.dataCoupon.point,
        startDate: props.dataCoupon.startDate,
        status: props.dataCoupon.status,
        tierConfig: props.dataCoupon.tierConfig,
        branchDetail: props.dataCoupon.branchDetail,
        couponType: props.dataCoupon.couponType,
        thumbnailImg: props.dataCoupon.thumbnailImg,
      },
      {
        code: props.dataCoupon.redeemCode,
        id: props.dataCoupon.redeemId,
        expiredAt: props.dataCoupon.redeemExpiredAt,
        expiredIn: parseFloat(`${_expiredInMin + _expiredInSec / 60}`),
        createdOn: props.dataCoupon.redeemCreatedOn,
        isUsed: props.dataCoupon.redeemIsUsed,
        transaction: props.dataCoupon.redeemTransaction,
        branchId: props.dataCoupon.redeemBranchId,
        branchName: props.dataCoupon.redeemBranchName,
        branchCode: props.dataCoupon.redeemBranchCode,
      }
    );

    if (
      moment(new Date()).format() >
        moment(props.dataCoupon.redeemExpiredAt).format() ||
      props.dataCoupon.redeemIsUsed === 1
    ) {
      navigate("/coupon/history/detail");
    } else {
      navigate("/coupon/history/redeem");
    }
  };
  /* End Handle goto coupon detail page */

  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  /* Check image exists */
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    // console.log("props", props);

    const img = new Image();
    img.src = props.dataCoupon.thumbnailImg;

    img.onload = () => {
      setImageExists(true);
    };

    // img.onerror = () => {
    //   setImageExists(false);
    // };
  }, [props.dataCoupon.thumbnailImg]);
  /* End Check image exists */

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article
      ref={eleRef}
      data-id={props.dataCoupon.redeemId}
      data-type={props.dataCoupon.type}
      data-createdon={props.dataCoupon.redeemCreatedOn}
      data-expiredat={props.dataCoupon.redeemExpiredAt}
    >
      <div className="bPic">
        {imageExists ? (
          <img src={props.dataCoupon.thumbnailImg} />
        ) : (
          <img src={img_icoTicket} />
        )}
      </div>
      <div className="bDetails">
        <div className="bValue">
          <p>{props.dataCoupon.name}</p>
          <div
            className={`tUse ${
              moment(new Date()).format() >
              moment(props.dataCoupon.redeemExpiredAt).format()
                ? "tExp"
                : ""
            }`}
          >
            {moment(new Date()).format() >
            moment(props.dataCoupon.redeemExpiredAt).format()
              ? "หมดอายุ"
              : props.dataCoupon.redeemIsUsed === 1
              ? "ใช้แล้ว"
              : "ยังไม่ได้ใช้"}
          </div>
        </div>
        <div className="bPoint">
          <div className="bBtn">
            <a href="#" onClick={handleGotoCouponHistoryDetail}>
              ดูรายละเอียด
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardCouponHistory;
