import { get, post } from "../Config";

export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}
export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}
/* ######################################################################## */

export function accessToken({ lineUid }) {
  return post({
    url: `/api/sb/v1/customer/accessToken/${lineUid}`,
    timeOut: 20000,
  });
}
export function searchCustomer({ params }) {
  return get({ url: `/api/sb/v1/customer/search`, params });
}
export function checkDupMobileNo({ params }) {
  return get({ url: `/api/sb/v1/customer/mobile/existed`, params });
}
export function createCustomer({ data }) {
  return post({ url: `/api/sb/v1/customer/create`, data, timeOut: 20000 });
}
export function updateCustomer({ data }) {
  return post({ url: `/api/sb/v1/customer/update`, data, timeOut: 20000 });
}
export function custProfile({ params }) {
  return get({ url: `/api/sb/v1/customer/profile`, params });
}
export function tierConfig({ params }) {
  return get({ url: `/api/sb/v1/tier`, params });
}

export function pointHistory({ params }) {
  return get({ url: `/api/sb/v1/customer/point-history`, params });
}
export function transaction({ params }) {
  return get({ url: `/api/sb/v1/customer/transaction`, params });
}
export function buyTransaction({ params }) {
  return get({ url: `/api/sb/v1/customer/buy-transaction`, params });
}
export function returnTransaction({ params }) {
  return get({ url: `/api/sb/v1/customer/return-transaction`, params });
}

export function branch({ params }) {
  return get({ url: `/api/sb/v1/branch`, params });
}

export function custCoupon({ params }) {
  return get({ url: `/api/sb/v1/customer/coupon`, params });
}
export function redeemCoupon({ data }) {
  return post({
    url: `/api/sb/v1/customer/coupon/redeem`,
    data,
    timeOut: 20000,
  });
}
export function couponHistory({ params }) {
  return get({ url: `/api/sb/v1/customer/coupon/history`, params });
}
export function custPromotion({ params }) {
  return get({ url: `/api/sb/v1/customer/promotion`, params });
}

export function telepharmacy({ params }) {
  return get({ url: `/api/sb/v1/customer/telepharmacy`, params });
}

export function custSurvey({ params }) {
  return get({ url: `/api/sb/v1/customer/survey`, params });
}

export function createSurvey({ data }) {
  return post({
    url: `/api/sb/v1/customer/survey/create`,
    data,
    timeOut: 20000,
  });
}

export function custSurveyHistory({ params }) {
  return get({ url: `/api/sb/v1/customer/survey/history`, params });
}
