import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSpring, useTransition, animated } from "react-spring";

import { branch } from "../../services/Api/Module/Customer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import img_marker from "../../assets/img/ui/map/marker_m.png";

const BranchMap = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle branch location list */
  const [branchLocationListToggle, setBranchLocationListToggle] =
    useState(false);

  const openBranchLocationListStyles = useSpring({
    opacity: branchLocationListToggle ? 0 : 1,
    display: branchLocationListToggle ? "none" : "block",
    config: { duration: 500 },
  });

  const branchLocationListStyles = useSpring({
    transform: branchLocationListToggle
      ? "translateX(0%)"
      : "translateX(-100%)",
  });

  const closeBranchLocationListStyles = useSpring({
    opacity: branchLocationListToggle ? 1 : 0,
    display: branchLocationListToggle ? "block" : "none",
    config: { duration: 500 },
  });

  const overlayBranchLocationListStyles = useSpring({
    opacity: branchLocationListToggle ? 1 : 0,
    display: branchLocationListToggle ? "block" : "none",
    config: { duration: 500 },
  });

  const handleBranchLocationList = (e) => {
    e.preventDefault();
    // console.log("click");

    setBranchLocationListToggle(!branchLocationListToggle);
  };

  const listenerGoToLocation = () => {
    const btnGoToLocation = document.querySelectorAll(".go-to-location");
    // console.log(btnGoToLocation);

    for (let i = 0; i < btnGoToLocation.length; i++) {
      btnGoToLocation[i].addEventListener("click", (e) => {
        e.preventDefault();

        const _id = e.currentTarget.dataset.id;
        // console.log(_id);

        const _tmpLocation = branchLocation;
        const _item = _tmpLocation.find((item) => item.id === _id);
        // console.log(_tmpLocation, _item.idx, _item.lat, _item.lng);

        _map.setCenter(new window.google.maps.LatLng(_item.lat, _item.lng));
        _map.setZoom(18);

        window.google.maps.event.trigger(_markers[_item.idx], "click");

        const _bBranch = document.querySelectorAll(".bBranch[data-id]");
        for (let i = 0; i < _bBranch.length; i++) {
          _bBranch[i].classList.remove("active");
        }

        document
          .querySelector(`.bBranch[data-id="${_id}"]`)
          .classList.add("active");

        setBranchLocationListToggle(false);
      });
    }
  };

  const [filterRegion, setFilterRegion] = useState("");
  const [filterProvince, setFilterProvince] = useState("");

  const [selectRegion, setSelectRegion] = useState([]);
  const [selectProvince, setSelectProvince] = useState([]);

  const listenerFilterLocation = () => {
    const selectRegion = document.querySelector("#selectRegion");
    selectRegion.addEventListener("change", (e) => {
      // console.log(e.target.value);

      setFilterRegion(e.target.value);
    });

    const selectProvince = document.querySelector("#selectProvince");
    selectProvince.addEventListener("change", (e) => {
      // console.log(e.target.value);

      setFilterProvince(e.target.value);
    });
  };

  useEffect(() => {
    // console.log(filterRegion, filterProvince);

    const _bBranch = document.querySelectorAll(".bBranch[data-province]");

    if (filterRegion !== "" && filterProvince !== "") {
      for (let i = 0; i < _bBranch.length; i++) {
        if (
          _bBranch[i].dataset.region === filterRegion &&
          _bBranch[i].dataset.province === filterProvince
        ) {
          _bBranch[i].style.display = "block";
        } else {
          _bBranch[i].style.display = "none";
        }
      }

      setSelectProvince([]);
      const _tmpProvince = branchProvince.filter((item) => {
        return item.region.trim() === filterRegion.trim();
      });
      setSelectProvince(_tmpProvince);
    } else if (filterRegion !== "" && filterProvince === "") {
      for (let i = 0; i < _bBranch.length; i++) {
        if (_bBranch[i].dataset.region === filterRegion) {
          _bBranch[i].style.display = "block";
        } else {
          _bBranch[i].style.display = "none";
        }
      }

      setSelectProvince([]);
      const _tmpProvince = branchProvince.filter((item) => {
        return item.region.trim() === filterRegion.trim();
      });
      setSelectProvince(_tmpProvince);
    } else if (filterRegion === "" && filterProvince !== "") {
      for (let i = 0; i < _bBranch.length; i++) {
        if (_bBranch[i].dataset.province === filterProvince) {
          _bBranch[i].style.display = "block";
        } else {
          _bBranch[i].style.display = "none";
        }
      }

      setSelectProvince([]);
      setSelectProvince(branchProvince);
    } else {
      for (let i = 0; i < _bBranch.length; i++) {
        _bBranch[i].style.display = "block";
      }

      setSelectProvince([]);
      setSelectProvince(branchProvince);
    }
  }, [filterRegion, filterProvince]);
  /* End Handle branch location list */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [isHasMyLocation, setIsHasMyLocation] = useState(null);
  const [myLocation, setMyLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const getBranch = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await branch({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getBranch resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getBranch >> ", e);
    }

    return _return;
  };

  const getLocationCallback = (position) => {
    // console.log("getLocationCallback", position);

    setMyLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    setIsHasMyLocation(true);
  };

  const getLocationErrorCallback = (error) => {
    // console.log("getLocationErrorCallback", error);

    setIsHasMyLocation(false);
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getLocationCallback,
        getLocationErrorCallback
      );
    } else {
      console.log("Geolocation is not available in your browser.");

      setIsHasMyLocation(false);
    }
  }, []);
  /* End First event render */

  const [branchLocation, setBranchLocation] = useState([]);
  const [branchRegion, setBranchRegion] = useState([]);
  const [branchProvince, setBranchProvince] = useState([]);

  useEffect(() => {
    // console.log(stateProfile, isHasMyLocation, myLocation);

    if (isHasMyLocation !== null) {
      getBranch({}).then((_rsDataBranch) => {
        // console.log("_rsDataBranch >>", _rsDataBranch);

        if (
          _rsDataBranch.status === true &&
          _rsDataBranch.result.length !== 0
        ) {
          const _length = _rsDataBranch.result.length;
          let _branch = _rsDataBranch.result;
          // console.log(_branch);

          for (let i = 0; i < _length; i++) {
            if (isHasMyLocation || stateProfile.branch !== null) {
              let _locationA = {
                latitude: null,
                longitude: null,
              };

              if (isHasMyLocation) {
                _locationA = new window.google.maps.LatLng(
                  myLocation.latitude,
                  myLocation.longitude
                );
              } else if (stateProfile.branch !== null) {
                let _myBranch = {};
                const _tmpBranch = _branch.filter((item) => {
                  return parseInt(item.id) === stateProfile.branch;
                });
                if (
                  typeof _tmpBranch.length !== "undefined" &&
                  _tmpBranch.length !== 0
                ) {
                  _myBranch = _tmpBranch[0];

                  _locationA = new window.google.maps.LatLng(
                    _myBranch.latitude,
                    _myBranch.longitude
                  );
                }
              }

              const _locationB = new window.google.maps.LatLng(
                _branch[i].latitude,
                _branch[i].longitude
              );

              const _dist =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  _locationA,
                  _locationB
                );
              _branch[i].distance = parseFloat((_dist / 1000).toFixed(2)); //_dist;
            }

            if (!isHasMyLocation || stateProfile.branch === null) {
              _branch[i].distance = "-";
            }

            if (_branch[i].region !== null && _branch[i].region !== "") {
              // console.log(_branch[i], _branch[i].region);

              const _found = branchRegion.find(
                (item) => item === _branch[i].region
              );
              if (typeof _found === "undefined") {
                branchRegion.push(_branch[i].region);
              }
            }

            if (_branch[i].province !== null && _branch[i].province !== "") {
              // console.log(_branch[i], _branch[i].province);

              const _found = branchProvince.find(
                (item) => item.province === _branch[i].province
              );
              if (typeof _found === "undefined") {
                branchProvince.push({
                  region: _branch[i].region,
                  province: _branch[i].province,
                });
              }
            }
          }

          setSelectRegion(branchRegion);
          setSelectProvince(branchProvince);

          if (isHasMyLocation || stateProfile.branch !== null) {
            _branch.sort((a, b) => a.distance - b.distance);
            // console.log(_branch);
          }

          for (let i = 0; i < _length; i++) {
            let _tDetailHead = document.createElement("p");
            _tDetailHead.classList.add("tDetailHead");
            _tDetailHead.append(_branch[i].nameFe);

            let _boldText = document.createElement("p");
            _boldText.classList.add("boldText");
            _boldText.append(
              `${
                _branch[i].latitude !== "0" && _branch[i].longitude !== "0"
                  ? _branch[i].distance
                  : "-"
              } กม.`
            );
            let _rightIcon = document.createElement("a");
            _rightIcon.classList.add("rightIcon");
            _rightIcon.classList.add("go-to-location");
            _rightIcon.href = "#";
            _rightIcon.setAttribute("data-id", _branch[i].id);
            let _tDistance = document.createElement("div");
            _tDistance.classList.add("tDistance");
            _tDistance.append(_boldText, _rightIcon);

            // let _span = document.createElement("span");
            // _span.append("08:00 - 23:00 น.");
            // let _pA = document.createElement("p");
            // _pA.append("เวลาทำการ: ", _span);
            // let _tTime = document.createElement("div");
            // _tTime.classList.add("tTime");
            // _tTime.append(_pA);
            let _bDistance = document.createElement("div");
            _bDistance.classList.add("bDistance");
            _bDistance.append(_tDistance);
            // _bDistance.append(_tTime);

            let _bText = document.createElement("div");
            _bText.classList.add("bText");
            _bText.append(_tDetailHead, _bDistance);

            let _logoLine;
            if (_branch[i].lineUrl) {
              _logoLine = document.createElement("a");
              _logoLine.classList.add("logoLine");
              _logoLine.href = _branch[i].lineUrl;
            }
            let _logoTel;
            if (_branch[i].tel) {
              _logoTel = document.createElement("a");
              _logoTel.classList.add("logoTel");
              _logoTel.href = `tel:${_branch[i].tel}`;
            }
            let _tContract = document.createElement("div");
            _tContract.classList.add("tContract");
            if (typeof _logoLine !== "undefined") {
              _tContract.append(_logoLine);
            }
            if (typeof _logoTel !== "undefined") {
              _tContract.append(_logoTel);
            }
            let _pB = document.createElement("p");
            _pB.append("ช่องทางการติดต่อ:");
            let _bContract = document.createElement("div");
            _bContract.classList.add("bContract");
            _bContract.append(_pB, _tContract);

            let _bBranchDetail = document.createElement("div");
            _bBranchDetail.classList.add("bBranchDetail");
            _bBranchDetail.append(_bText);
            if (
              typeof _logoLine !== "undefined" ||
              typeof _logoTel !== "undefined"
            ) {
              _bBranchDetail.append(_bContract);
            }

            let _bBranch = document.createElement("div");
            _bBranch.classList.add("bBranch");
            _bBranch.setAttribute("data-id", _branch[i].id);
            _bBranch.setAttribute("data-region", _branch[i].region);
            _bBranch.setAttribute("data-province", _branch[i].province);
            if (
              stateProfile.branch !== null &&
              parseInt(_branch[i].id) === stateProfile.branch &&
              !isHasMyLocation
            ) {
              _bBranch.classList.add("active");
            }
            _bBranch.append(_bBranchDetail);

            document.querySelector("#branchLocationList").append(_bBranch);

            setBranchLocation((prevBranchLocation) => [
              ...prevBranchLocation,
              {
                lat: parseFloat(_branch[i].latitude),
                lng: parseFloat(_branch[i].longitude),
                html:
                  "" +
                  '<div className="marker-info-content" style="overflow: auto;">' +
                  '<h5 className="content-title">' +
                  _branch[i].nameFe +
                  "</h5>" +
                  '<div className="content-address">' +
                  _branch[i].address +
                  "</div>" +
                  '<a href="https://www.google.com/maps?hl=th&output=classic&f=d&daddr=' +
                  _branch[i].latitude +
                  "+N," +
                  _branch[i].longitude +
                  '+E" className="content-route" target="_blank"><span>แสดงเส้นทาง</span></a>' +
                  "</div>",
                idx: i,
                id: _branch[i].id,
                name: _branch[i].nameFe,
                tel: _branch[i].tel,
                lineUrl: _branch[i].lineUrl,
                lineId: _branch[i].lineId,
                address: _branch[i].address,
                distance: _branch[i].distance,
              },
            ]);
          }
        }

        setIsLoading(false);
      });
    }
  }, [isHasMyLocation]);

  /* Google map api (Legacy) (.Marker is deprecated) */
  const _mapRef = useRef(null);
  const [_map, _setMap] = useState(null);
  const [_markers, _setMarkers] = useState([]);

  let _currentInfo = null;

  // useEffect(() => {
  //   // console.log("map loading", branchLocation, branchRegion, branchProvince);

  //   if (!isLoading && window.google) {
  //     _setMap(
  //       new window.google.maps.Map(_mapRef.current, {
  //         center: {
  //           lat: 13.7248936,
  //           lng: 100.493024,
  //         },
  //         zoom: 12,
  //         mapTypeControl: false,
  //         streetViewControl: false,
  //       })
  //     );
  //   }
  // }, [isLoading]);

  // useEffect(() => {
  //   // console.log(branchLocation.length, _map);

  //   if (_map !== null) {
  //     if (isHasMyLocation) {
  //       const _myMarker = new window.google.maps.Marker({
  //         map: _map,
  //         // icon: {
  //         //   path: window.google.maps.SymbolPath.CIRCLE,
  //         //   scale: 10,
  //         //   fillOpacity: 1,
  //         //   strokeWeight: 3,
  //         //   fillColor: "#5384ED",
  //         //   strokeColor: "#ffffff",
  //         // },
  //         icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAvxJREFUSEu1Vl9IU2EUP9+3u92765pNy8xMLQ2DypQkH4wYRZRGPmUgUktCe9AIwoTRyx6KkUIE6UNKmPRH0J4stB4ECXowDM0KElyZWVmpw7Xt3m33fl9cZXK9bm4Fu2/3nt93fpxzfuf3XQQxngN1VG/cASYj9hspxYwCR4hIAuEF4TN437Sj0HopULSg1fHLBKw5HetkM5UhIg7pgBJZ54GAZ3bIkeaNlGvNQYeD4peMuJ3qyKZY1anjSMZzhyXuq8OByKrv6helHZbcYJ5MJP5fkoexOsz43S7DpLptKxVUVlLdQpE3nyBs/J/k4TOYEiFl1DTR24vkpXmFA1anOweBITVS8pJcnHymmClN24h3EQJkzkNcPSPSq2EXWYyEp4Dnh+zGqRUCZaCITcrXgnenY95eYajZZsHVGIFBDAFgBGBgAAiF4Dc3eeTsC3Z+nCV+7Vka8E0og1+qwOoU8xDIyWqQkrylimvlWSgYGJOgdzgE0/PL88tKxVBZooeyQgaEIIw3PhYbtCQUdItDdm4SWR2Uwby/QCvFrjquPtOCa5xPAzD4Xoo4lqN7GbCfYmHGTTpt7WLbKvUoEvbz4+iQk1r04N+pDhZlYXNLFfd84K1kuNUfWHfmV8pZKN/PBBu7xROj08SjBoeA/4SO3fRlSAS2qgPNVdzx4mx840KHAFO/V8l6DVnOZgz3ao0w8oVca+oWX6gBDIYf6Mh1IVu7VA8ucpdTTfjsyRZfXIp91sjDvI8+PHdXvK1dvogEXXXcpS1mbCtrjo9goCkJfi6SLluHeGcNQcJblPAhR5VpLdeQmYLPO/sCMPghikz3MGCvYGFmgdy3dYitEWW6/qKxbTyL9vWPSfBEs2inS/RQXsiAP0DfXe0O1EddtCWCGFaRacHVSGMVlEJwJl6rWK5CyEFAopvdQUNpmhmWzc4Lrp7XwfjNTiFIuF0rJAm9cMIKSOiVqZZZwi59rfkobTNkeDaYjAwnh7Beiev0JOQVJDH43fwn1m/LXzMPssYKF63yAAAAAElFTkSuQmCC",
  //         animation: window.google.maps.Animation.DROP,
  //       });

  //       _myMarker.setPosition(
  //         new window.google.maps.LatLng(
  //           myLocation.latitude,
  //           myLocation.longitude
  //         )
  //       );

  //       _map.setCenter(
  //         new window.google.maps.LatLng(
  //           myLocation.latitude,
  //           myLocation.longitude
  //         )
  //       );
  //       _map.setZoom(18);

  //       addYourLocationButton();
  //     } else {
  //       if (branchLocation.length > 0) {
  //         if (stateProfile.branch !== null) {
  //           const _tmpLocation = branchLocation;
  //           const _item = _tmpLocation.find(
  //             (item) => parseInt(item.id) === stateProfile.branch
  //           );
  //           // console.log(_tmpLocation, _item.idx, _item.lat, _item.lng);

  //           _map.setCenter(new window.google.maps.LatLng(_item.lat, _item.lng));
  //           _map.setZoom(18);

  //           window.google.maps.event.trigger(_markers[_item.idx], "click");
  //         }
  //       }
  //     }

  //     if (branchLocation.length > 0) {
  //       const _branchMarkers = branchLocation.map(function (item, i) {
  //         // console.log(item, i);

  //         const _branchMarker = new window.google.maps.Marker({
  //           position: new window.google.maps.LatLng(item.lat, item.lng),
  //           icon: {
  //             url: String(setIconMarker("")),
  //             scaledSize: new window.google.maps.Size(45, 45),
  //           },
  //           markerLat: item.lat,
  //           markerLng: item.lng,
  //           markerId: item.id,
  //           markerTitle: item.name,
  //         });

  //         const _infoWindow = new window.google.maps.InfoWindow({
  //           maxWidth: 300,
  //         });

  //         window.google.maps.event.addListener(_branchMarker, "click", function (e) {
  //           if (_currentInfo !== null) {
  //             _currentInfo.close(_map, _branchMarker);
  //           }

  //           _infoWindow.setContent(item.html);
  //           _infoWindow.open(_map, _branchMarker);

  //           _currentInfo = _infoWindow;

  //           _map.panTo(_branchMarker.position);
  //         });

  //         _branchMarker.setMap(_map);

  //         return _branchMarker;
  //       });

  //       _setMarkers(_branchMarkers);
  //     }
  //   }
  // }, [_map]);

  // const setIconMarker = (type) => {
  //   let _url = "";
  //   switch (type) {
  //     default:
  //       _url = img_marker;
  //       break;
  //   }
  //   return _url;
  // };

  const addYourLocationButton = () => {
    var controlDiv = document.createElement("div");
    controlDiv.id = "wrpMyLocation";

    var firstChild = document.createElement("button");
    firstChild.style.backgroundColor = "#fff";
    firstChild.style.border = "none";
    firstChild.style.outline = "none";
    firstChild.style.width = "40px";
    firstChild.style.height = "40px";
    firstChild.style.borderRadius = "2px";
    firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
    firstChild.style.cursor = "pointer";
    firstChild.style.marginRight = "10px";
    firstChild.style.padding = "0px";
    firstChild.title = "Your Location";
    controlDiv.appendChild(firstChild);

    var secondChild = document.createElement("div");
    secondChild.style.margin = "10px";
    secondChild.style.width = "20px";
    secondChild.style.height = "20px";
    secondChild.style.backgroundImage =
      "url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)";
    secondChild.style.backgroundSize = "200px 20px";
    secondChild.style.backgroundPosition = "0px 0px";
    secondChild.style.backgroundRepeat = "no-repeat";
    secondChild.id = "btnMyLocation";
    firstChild.appendChild(secondChild);

    firstChild.addEventListener("click", function () {
      var imgX = "0";
      var animationInterval = setInterval(function () {
        if (imgX == "-20") imgX = "0";
        else imgX = "-20";
        document.querySelector("#btnMyLocation").style.backgroundPosition =
          imgX + "px 0px";
      }, 500);

      _map.panTo({ lat: myLocation.latitude, lng: myLocation.longitude });

      clearInterval(animationInterval);
      document.querySelector("#btnMyLocation").style.backgroundPosition =
        "-160px 0px";
    });

    window.google.maps.event.addListener(_map, "dragend", function () {
      document.querySelector("#btnMyLocation").style.backgroundPosition =
        "0px 0px";
    });

    controlDiv.index = 1;

    _map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      controlDiv
    );
  };
  /* End Google map api (Legacy) (.Marker is deprecated) */

  useEffect(() => {
    // console.log(_markers);

    if (_markers.length > 0) {
      listenerFilterLocation();
      listenerGoToLocation();
    }
  }, [_markers]);

  /* Google map api new version */
  useEffect(() => {
    // console.log("map loading", isLoading, window.google);

    if (!isLoading && window.google) {
      _setMap(
        new window.google.maps.Map(_mapRef.current, {
          center: {
            lat: 13.7248936,
            lng: 100.493024,
          },
          zoom: 12,
          mapTypeControl: false,
          streetViewControl: false,
          mapId: "HU_MAP",
        })
      );
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log("map loading", _map);

    if (_map !== null) {
      if (isHasMyLocation) {
        const _myMarkerImg = document.createElement("img");
        _myMarkerImg.src =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAvxJREFUSEu1Vl9IU2EUP9+3u92765pNy8xMLQ2DypQkH4wYRZRGPmUgUktCe9AIwoTRyx6KkUIE6UNKmPRH0J4stB4ECXowDM0KElyZWVmpw7Xt3m33fl9cZXK9bm4Fu2/3nt93fpxzfuf3XQQxngN1VG/cASYj9hspxYwCR4hIAuEF4TN437Sj0HopULSg1fHLBKw5HetkM5UhIg7pgBJZ54GAZ3bIkeaNlGvNQYeD4peMuJ3qyKZY1anjSMZzhyXuq8OByKrv6helHZbcYJ5MJP5fkoexOsz43S7DpLptKxVUVlLdQpE3nyBs/J/k4TOYEiFl1DTR24vkpXmFA1anOweBITVS8pJcnHymmClN24h3EQJkzkNcPSPSq2EXWYyEp4Dnh+zGqRUCZaCITcrXgnenY95eYajZZsHVGIFBDAFgBGBgAAiF4Dc3eeTsC3Z+nCV+7Vka8E0og1+qwOoU8xDIyWqQkrylimvlWSgYGJOgdzgE0/PL88tKxVBZooeyQgaEIIw3PhYbtCQUdItDdm4SWR2Uwby/QCvFrjquPtOCa5xPAzD4Xoo4lqN7GbCfYmHGTTpt7WLbKvUoEvbz4+iQk1r04N+pDhZlYXNLFfd84K1kuNUfWHfmV8pZKN/PBBu7xROj08SjBoeA/4SO3fRlSAS2qgPNVdzx4mx840KHAFO/V8l6DVnOZgz3ao0w8oVca+oWX6gBDIYf6Mh1IVu7VA8ucpdTTfjsyRZfXIp91sjDvI8+PHdXvK1dvogEXXXcpS1mbCtrjo9goCkJfi6SLluHeGcNQcJblPAhR5VpLdeQmYLPO/sCMPghikz3MGCvYGFmgdy3dYitEWW6/qKxbTyL9vWPSfBEs2inS/RQXsiAP0DfXe0O1EddtCWCGFaRacHVSGMVlEJwJl6rWK5CyEFAopvdQUNpmhmWzc4Lrp7XwfjNTiFIuF0rJAm9cMIKSOiVqZZZwi59rfkobTNkeDaYjAwnh7Beiev0JOQVJDH43fwn1m/LXzMPssYKF63yAAAAAElFTkSuQmCC";

        const _myMarker = new window.google.maps.marker.AdvancedMarkerElement({
          map: _map,
          position: {
            lat: myLocation.latitude,
            lng: myLocation.longitude,
          },
          title: "My Location",
          content: _myMarkerImg,
        });

        addYourLocationButton();
      } else {
        if (branchLocation.length > 0) {
          if (stateProfile.branch !== null) {
            const _tmpLocation = branchLocation;
            const _item = _tmpLocation.find(
              (item) => parseInt(item.id) === stateProfile.branch
            );
            // console.log(_tmpLocation, _item.idx, _item.lat, _item.lng);

            _map.setCenter(new window.google.maps.LatLng(_item.lat, _item.lng));
            _map.setZoom(18);

            window.google.maps.event.trigger(_markers[_item.idx], "click");
          }
        }
      }

      if (branchLocation.length > 0) {
        const _branchMarkers = branchLocation.map(function (item, i) {
          // console.log(item, i);

          const _markerImg = document.createElement("img");
          _markerImg.src = img_marker;

          const _branchMarker =
            new window.google.maps.marker.AdvancedMarkerElement({
              map: _map,
              position: {
                lat: item.lat,
                lng: item.lng,
              },
              title: item.name,
              content: _markerImg,
            });

          const _infoWindow = new window.google.maps.InfoWindow({
            maxWidth: 300,
          });

          _branchMarker.addListener("click", () => {
            if (_currentInfo !== null) {
              _currentInfo.close(_map, _branchMarker);
            }

            _infoWindow.setContent(item.html);
            _infoWindow.open(_map, _branchMarker);

            _currentInfo = _infoWindow;

            _map.panTo(_branchMarker.position);
          });

          return _branchMarker;
        });

        _setMarkers(_branchMarkers);
      }
    }
  }, [_map]);
  /* End Google map api new version */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageBranchMap">
        <header>
          <div className="tBranchHeader">
            <h1>ค้นหาร้านยาของเรา</h1>
          </div>
        </header>
        <div className="bSelect">
          <div className="selectBranch">
            <a className="" href="#" onClick={handleGotoPage} data-page="branch">
              สาขาใกล้ฉัน
            </a>
            <a
              className="active"
              href="#"
              onClick={handleGotoPage}
              data-page="branch/map"
            >
              สาขาทั้งหมด
            </a>
          </div>
        </div>
        <main>
          <div className="wrapContent">
            <section id="pBranchMap">
              <div className="bMap">
                <animated.button
                  className="openSearch"
                  style={{ ...openBranchLocationListStyles, zIndex: "49" }}
                  onClick={handleBranchLocationList}
                >
                  ค้นหา
                </animated.button>
                <animated.div
                  className="findDetail show"
                  style={{ ...branchLocationListStyles }}
                >
                  <div className="bBranchList">
                    <div className="bSearch">
                      <div className="control-group">
                        <div className="select">
                          <select
                            name="region"
                            id="selectRegion"
                            defaultValue=""
                          >
                            <option value="">ทั้งหมด</option>
                            {selectRegion.length > 0 &&
                              selectRegion.map((item, idx) => (
                                <option key={idx + 1} value={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="select">
                          <select
                            name="province"
                            id="selectProvince"
                            defaultValue=""
                          >
                            <option value="">เลือกจังหวัด</option>
                            {selectProvince.length > 0 &&
                              selectProvince.map((item, idx) => (
                                <option key={idx + 1} value={item.province}>
                                  {item.province}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="listMain">
                      <div className="content" id="branchLocationList"></div>
                    </div>
                  </div>
                </animated.div>
                <animated.button
                  className="btnClose show"
                  style={{ ...closeBranchLocationListStyles, zIndex: "49" }}
                  onClick={handleBranchLocationList}
                ></animated.button>
                <animated.div
                  style={{
                    ...overlayBranchLocationListStyles,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    position: "absolute",
                    zIndex: "48",
                  }}
                ></animated.div>
                {isLoading ? (
                  <>
                    <p
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        textAlign: "center",
                        margin: "auto",
                        alignContent: "center",
                        color: "#fff",
                      }}
                    >
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                    </p>
                  </>
                ) : (
                  <>
                    <div
                      ref={_mapRef}
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                  </>
                )}
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={handleGotoPage}
                  data-page="branch"
                >
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default BranchMap;
