import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  id: 0,
  data: {},
};

export const SurveySlice = createSlice({
  name: "Survey",
  initialState: defaultData,
  reducers: {
    setSurvey: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetSurvey: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setSurvey, resetSurvey } = SurveySlice.actions;

export default SurveySlice.reducer;
