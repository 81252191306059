import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import helper from "../../../../services/helper";

const ModalDisease = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  diseases = [],
  selected = [],
  other=""
}) => {
  const [isOtherCheck, setIsOtherCheck] = useState(false);
  const [otherDiseaseValue, setOtherDiseaseValue] = useState("");
  const [selectedDiseasesId, setSelectedDiseasesId] = useState([]);
  const handleSubmit = () => {
    if(!isOtherCheck){
      setOtherDiseaseValue("");
    }
    onConfirm(selectedDiseasesId, otherDiseaseValue);
  };
  const handleOtherCheck = () => {
    setIsOtherCheck(prevState => {
      if (prevState) {
        setOtherDiseaseValue("");
      }
      return !prevState;
    });
  };
  useEffect(() => {
    setSelectedDiseasesId(selected);
    if(other!=""){
      setIsOtherCheck(true);
      setOtherDiseaseValue(other);
    }
  }, [selected]);

  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div
        className="bModal bCard"
        id="modaldiseat"
        style={{ display: "block" }}
      >
        <div className="btnClose btnCloseModal" onClick={onClose}>
          <img src="./assets/img/ci/icon-modal/ico-xmark-healthservice.svg" />
        </div>
        <div className="bDetail">
          <div className="bHead">
            <p className="tBold">ระบุโรคประจำของคุณ</p>
            <p>เลือกได้มากกว่า 1 ข้อ</p>
          </div>
          <div className="bInput">
            {diseases?.map((item) => (
              <div className="bSelect" key={item.id}>
                <label className="control control--checkbox">
                  {item.name}
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={selectedDiseasesId.some(selected => selected == item.id)}
                    onChange={() => {
                      let updateState = helper.FNFORM.handleSelectChange(selectedDiseasesId, item.id)
                      setSelectedDiseasesId(updateState)
                    }}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            ))}
            <div className="bSelect">
              <label className="control control--checkbox">
                อื่นๆ
                <input
                  type="checkbox"
                  checked={isOtherCheck}
                  onChange={handleOtherCheck}
                />
                <div className="control__indicator"></div>
              </label>
            </div>
            {isOtherCheck && (
              <div className="control-group">
              <input
                type="text"
                placeholder="ระบุชื่อโรคประจำตัว"
                onChange={(e) => {
                  setOtherDiseaseValue(e.target.value);
                }}
                value={otherDiseaseValue}
              />
              </div>
            )}
          </div>

          <button
            className="btn"
            onClick={handleSubmit}
            id="btnSubmitDisease"
            disabled={isOtherCheck && otherDiseaseValue.trim() == ""}
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalDisease;
