import React, { useEffect, useState } from "react";

import $ from "jquery";
import jBox from "jbox";

import moment from "moment";

import img_icoXmarkHealthservice from "../../../../assets/img/ci/icon-modal/ico-xmark-healthservice.svg";

const ModalHealthInfoFilter = ({
  open = false,
  onClose = () => {},
  maxYear = "",
  minYear = "",
  submitCallback = () => {},
}) => {
  /* Set select option */
  let optionsYear = [];

  if (minYear !== "" && maxYear !== "") {
    for (let i = maxYear; i >= minYear; i--) {
      optionsYear.push({
        value: i,
        label: i,
      });
    }
  }
  // console.log(optionsYear);
  /* End Set select option */

  const [modalFilter, setModalFilter] = useState(null);
  const [submitFilter, setSubmitFilter] = useState(false);

  const openModal = () => {
    modalFilter.open();
  };

  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnCloseModalFilter = document.querySelector(
      "#ModalFilterGraph .btnCloseModal"
    );
    btnCloseModalFilter.addEventListener("click", () => {
      closeModal();
    });
  };

  const [typeFilter, setTypeFilter] = useState(null);
  const [dataYear, setDataYear] = useState(null);
  const [dataYearPeriod, setDataYearPeriod] = useState({});
  const [dataYearStart, setDataYearStart] = useState(null);
  const [dataYearEnd, setDataYearEnd] = useState(null);
  const [dataSixMonth, setDataSixMonth] = useState({});
  const [dataFilter, setDataFilter] = useState({});

  const listenerResetFilter = () => {
    const btnResetFilter = document.querySelector(
      "#ModalFilterGraph .btnResetFilter"
    );
    btnResetFilter.addEventListener("click", () => {
      let radioTypeFilter = document.querySelector(
        "#ModalFilterGraph input[name='typeFilter'][value='year']"
      );

      radioTypeFilter.checked = true;

      let selectYear = document.querySelector(
        "#ModalFilterGraph select[name='selectYear']"
      );

      selectYear.value = optionsYear[0].value;

      document.querySelector("#ModalFilterGraph .bYear").style.display =
        "block";
      document.querySelector("#ModalFilterGraph .bPeriod").style.display =
        "none";
      document.querySelector("#ModalFilterGraph .bMonth").style.display =
        "none";

      // clearStateFilter();
    });
  };

  const listenerSubmitFilter = () => {
    const btnSubmitFilter = document.querySelector(
      "#ModalFilterGraph .btnSubmitFilter"
    );
    btnSubmitFilter.addEventListener("click", () => {
      const radioTypeFilter = document.querySelector(
        "#ModalFilterGraph input[name='typeFilter']:checked"
      );

      const _typeFilter = radioTypeFilter.value;
      setTypeFilter(_typeFilter);

      switch (_typeFilter) {
        case "year":
          const selectYear = document.querySelector(
            "#ModalFilterGraph select[name='selectYear']"
          );

          setDataYear(selectYear.value);
          break;

        case "yearPeriod":
          const selectYearStart = document.querySelector(
            "#ModalFilterGraph select[name='selectYearStart']"
          );
          const selectYearEnd = document.querySelector(
            "#ModalFilterGraph select[name='selectYearEnd']"
          );

          setDataYearPeriod({
            yearStart: parseInt(selectYearStart.value),
            yearEnd: parseInt(selectYearEnd.value),
          });
          break;

        case "sixMonth":
          const _month = [];
          let _dateStart = moment().subtract(5, "months");

          for (let i = 0; i <= 5; i++) {
            _month.push({
              year: _dateStart.format("YYYY"),
              month: _dateStart.format("MM"),
            });
            _dateStart = _dateStart.add(1, "month");
          }

          setDataSixMonth(_month);
          break;

        default:
          // console.log("switch >> default");
          break;
      }

      setSubmitFilter(true);

      closeModal();
    });
  };

  const clearStateFilter = () => {
    // console.log("clearStateFilter");
    setTypeFilter("");
  };

  const handleOnTypeChange = (e) => {
    // console.log(e.target.value);

    switch (e.target.value) {
      case "year":
        document.querySelector("#ModalFilterGraph .bYear").style.display =
          "block";
        document.querySelector("#ModalFilterGraph .bPeriod").style.display =
          "none";
        document.querySelector("#ModalFilterGraph .bMonth").style.display =
          "none";
        break;

      case "yearPeriod":
        document.querySelector("#ModalFilterGraph .bYear").style.display =
          "none";
        document.querySelector("#ModalFilterGraph .bPeriod").style.display =
          "block";
        document.querySelector("#ModalFilterGraph .bMonth").style.display =
          "none";
        break;

      case "sixMonth":
        document.querySelector("#ModalFilterGraph .bYear").style.display =
          "none";
        document.querySelector("#ModalFilterGraph .bPeriod").style.display =
          "none";
        document.querySelector("#ModalFilterGraph .bMonth").style.display =
          "block";
        break;

      default:
        // console.log("switch >> default");
        break;
    }
  };

  const handleOnYearStartChange = (e) => {
    // console.log(e.target.value);
    // const _yearStart = parseInt(e.target.value);
    // setDataYearStart(_yearStart);
    // let _yearEnd = document.querySelector(
    //   "#ModalFilterGraph select[name='selectYearEnd']"
    // ).value;
    // // _yearStart === optionsYear[2].value
    // //   ? _yearStart
    // //   : _yearStart > optionsYear[2].value
    // //   ? _yearStart - 1
    // //   : _yearStart;
    // setDataYearEnd(_yearEnd);
  };

  /* First event render */
  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalFilterGraph"),
      onOpen: () => {
        // console.log('open');
        document
          .querySelectorAll(
            '#ModalFilterGraph input[type="radio"][name="typeFilter"]'
          )
          .forEach((radio) => {
            radio.addEventListener("change", (e) => handleOnTypeChange(e));
          });

        document
          .querySelector("#ModalFilterGraph select[name='selectYearStart']")
          .addEventListener("change", (e) => handleOnYearStartChange(e));
      },
      // onOpenComplete: () => {
      //   console.log('openComplete');
      // },
      onClose: () => {
        // console.log('close');
        document
          .querySelectorAll(
            '#ModalFilterGraph input[type="radio"][name="typeFilter"]'
          )
          .forEach((radio) => {
            radio.removeEventListener("change", handleOnTypeChange);
          });

        document
          .querySelector("#ModalFilterGraph select[name='selectYearStart']")
          .removeEventListener("change", handleOnYearStartChange);
      },
      // onCloseComplete: () => {
      //   console.log('closeComplete');
      // }
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalFilter useEffect2!! >>", modalFilter, open);

    if (modalFilter !== null && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)

      listenerResetFilter();
      listenerSubmitFilter();
    }
  }, [modalFilter, open]);

  useEffect(() => {
    switch (typeFilter) {
      case "year":
        setDataFilter({
          type: typeFilter,
          year: dataYear,
        });
        break;

      case "yearPeriod":
        setDataFilter({
          type: typeFilter,
          yearStart: dataYearPeriod["yearStart"],
          yearEnd: dataYearPeriod["yearEnd"],
        });
        break;

      case "sixMonth":
        setDataFilter({
          type: typeFilter,
          month: dataSixMonth,
        });
        break;

      default:
        // console.log("switch >> default");
        break;
    }
  }, [typeFilter, dataYear, dataYearPeriod, dataSixMonth]);

  useEffect(() => {
    // console.log(dataFilter, submitFilter);

    submitCallback(dataFilter, submitFilter);
  }, [dataFilter]);

  useEffect(() => {
    // console.log(dataYearStart, dataYearEnd);
  }, [dataYearStart, dataYearEnd]);

  return (
    <div className="bModal bCard" id="ModalFilterGraph">
      <div className="btnClose btnCloseModal">
        <img src={img_icoXmarkHealthservice} />
      </div>
      <div className="bInner">
        <div className="bTitle">
          <span>กรองข้อมูลโดย</span>
        </div>
        <div className="bFilterForm">
          <div className="bForm">
            <h4>เลือกตาม</h4>
            <div className="bRow">
              <div className="control-group selectType">
                <label className="control control--radio">
                  ปี
                  <input
                    type="radio"
                    name="typeFilter"
                    defaultChecked={true}
                    value="year"
                  />
                  <div className="control__indicator"></div>
                </label>
                <label className="control control--radio">
                  ช่วงปี
                  <input type="radio" name="typeFilter" value="yearPeriod" />
                  <div className="control__indicator"></div>
                </label>
                <label className="control control--radio">
                  6 เดือน
                  <input type="radio" name="typeFilter" value="sixMonth" />
                  <div className="control__indicator"></div>
                </label>
              </div>
            </div>
            <div className="bRow">
              <div className="bYear">
                <div className="control-group">
                  {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ Class error --> */}
                  <div className="tTitle">ปี(ค.ศ.)</div>
                  <div className="select">
                    <select
                      name="selectYear"
                      defaultValue={optionsYear[0].value}
                    >
                      {optionsYear.map(({ value, label }, index) => (
                        <option key={index + 1} value={value}>
                          {label}
                          {/* {parseInt(label) + 543} */}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label htmlFor="" className="tError">
                    กรุณาเลือกปี
                  </label>
                </div>
                <p>*สามารถเลือกตั้งแต่ปัจจุบัน - ย้อนหลังไม่เกิน 3 ปี</p>
              </div>
              <div className="bPeriod" style={{ display: "none" }}>
                <div className="control-group">
                  {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ Class error --> */}
                  <div className="tTitle">ปี(ค.ศ.)</div>
                  <div>
                    <div className="select">
                      <select
                        name="selectYearEnd"
                        defaultValue={optionsYear[0].value}
                        // disabled={true}
                      >
                        {optionsYear.map(({ value, label }, index) => (
                          <option key={index + 1} value={value}>
                            {label}
                            {/* {parseInt(label) + 543} */}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p>ถึง</p>
                    <div className="select">
                      <select
                        name="selectYearStart"
                        defaultValue={optionsYear[2].value}
                      >
                        {optionsYear.map(
                          ({ value, label }, index) => (
                            // dataYearStart === null ? (
                            <option
                              key={index + 1}
                              value={value}
                              // disabled={value === optionsYear[0].value}
                            >
                              {label}
                              {/* {parseInt(label) + 543} */}
                            </option>
                          )
                          // ) : (
                          //   <option
                          //     key={index + 1}
                          //     value={value}
                          //     disabled={value >= parseInt(dataYearStart)}
                          //     checked={value <= parseInt(dataYearStart)}
                          //   >
                          //     {label}
                          //     {/* {parseInt(label) + 543} */}
                          //   </option>
                          // )
                        )}
                      </select>
                    </div>
                  </div>
                  <label htmlFor="" className="tError">
                    กรุณาเลือกปี
                  </label>
                </div>
                <p>*สามารถเลือกตั้งแต่ปัจจุบัน - ย้อนหลังไม่เกิน 3 ปี </p>
              </div>
              <div className="bMonth" style={{ display: "none" }}>
                <p>*กำหนดระยะเวลาตั้งแต่ ปัจจุบัน - ย้อนหลัง 6 เดือน</p>
              </div>
            </div>
          </div>
          <div className="bBtn">
            <button className="btn btnClose btnSubmitFilter">ยืนยัน</button>
            <button className="btn outline btnClose btnResetFilter">
              รีเซ็ท
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHealthInfoFilter;
