import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OtpInput } from "reactjs-otp-input";
import { setRegister } from "../../../features/RegisterSlice";
import { setProfile } from "../../../features/ProfileSlice";
import {
  OtpRequest,
  OtpVerify,
  updateCustomer,
  custProfile,
  createCustomer,
  branch,
} from "../../../services/Api/Module/Customer";

import { genString, convertMobileFormat } from "../../../services/helper";

import PageTitleGuest from "../../../layouts/inc/PageTitleGuest";

import ModalContext from "../../../contexts/ModalContext";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoOtp from "../../../assets/img/ci/icon-register/ico-otp.svg";

const AuthOtp = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);
  // console.log(stateProfile);

  // const { t } = useTranslations(); // แปลภาษา

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-otp");
  const [step, setStep] = useState({});

  const PageRoute = {
    register: "/register",
    "auth-user": "/auth-user",
    welcome: "/welcome",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = () => {
    setPrevPage(page);

    if (stateRegister.prevPage === "auth-confirm") {
      navigate(PageRoute["auth-user"]);
    } else {
      navigate(PageRoute["register"]);
    }
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Handle OTP input */
  const [otp, setOtp] = useState("");
  // const [refOtp, setRefOtp] = useState("");

  async function handlePasteOtp(e) {
    // console.log("handlePasteOtp e >>", e.clipboardData.getData("text"));
    const textClipboard = e.clipboardData.getData("text");
    let maxLength = 6;

    if (textClipboard) {
      const smsCodeElements = document.querySelectorAll(".smsCode");

      for (let i = 0; i < maxLength; i++) {
        if (textClipboard[i]) {
          smsCodeElements[i].value = textClipboard[i];
        }
      }
      if (textClipboard.length) {
        if (textClipboard.length < maxLength) {
          smsCodeElements[textClipboard.length - 1].focus();
        } else {
          smsCodeElements[maxLength - 1].focus();
        }
      }
    }
  }
  async function handleInputOtp(e) {
    // console.log("handleInputOtp!!", e);

    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
      target.value = "";
      return;
    }

    if (val != "") {
      const next = target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }

    setValueOtp();
  }

  function handleKeyupOtp(e) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }

  function setValueOtp() {
    let otpValue = "";
    const smsCodeElements = document.querySelectorAll(".smsCode");
    for (const smsCodeElement of smsCodeElements) {
      // console.log("smsCodeElement.value >>", smsCodeElement.value);
      otpValue += smsCodeElement.value;
    }
    setOtp(otpValue);
  }

  const handleChangeOtp = (otp) => setOtp(otp);
  /* End Handle OTP input */

  /* Handle click submit form */
  // const [custCode, setCustCode] = useState(genString(6));

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(stateRegister);
    // console.log(otp);

    document
      .getElementsByClassName("btn-submit-otp")[0]
      .setAttribute("disabled", "");

    const payloadOtpVerify = {
      token: otpToken,
      pin: otp,
    };

    postOtpVerify(payloadOtpVerify).then((_rsDataOtpVerify) => {
      // console.log(_rsDataOtpVerify);

      if (
        _rsDataOtpVerify.result !== null &&
        _rsDataOtpVerify.result.status === "success"
      ) {
        let payload = {
          ...stateRegister.dataUser,
          isVerified: true,
          lineUid: stateProfile.lineUid,
        };

        /*** Verify customer ***/
        if (stateRegister.prevPage === "auth-confirm") {
          switch (stateRegister.dataFrom) {
            case "HUG_exist":
              payload = {
                ...payload,
                dataFrom: stateRegister.dataFrom,
              };

              postCreateCustomer(payload).then((_rsDataCreateCustomer) => {
                // console.log("_rsDataCreateCustomer >>", _rsDataCreateCustomer);

                if (
                  _rsDataCreateCustomer.status === true &&
                  _rsDataCreateCustomer.result.length !== 0
                ) {
                  getCustProfile({
                    custCode: stateRegister.dataUser.custCode,
                  }).then((_rsDataCustProfile) => {
                    // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                    document
                      .getElementsByClassName("btn-submit-otp")[0]
                      .removeAttribute("disabled");

                    if (
                      _rsDataCustProfile.status === true &&
                      _rsDataCustProfile.result.length !== 0
                    ) {
                      setDataProfile(_rsDataCustProfile.result);
                    } else {
                      console.warn(_rsDataCustProfile);
                    }
                  });
                } else {
                  console.warn(_rsDataCreateCustomer);
                }
              });
              break;

            case "HUG_update":
              // delete payload.custCode;
              delete payload.branch;
              delete payload.custType;
              delete payload.currentPoint;
              delete payload.totalBuy;

              postUpdateCustomer(payload).then((_rsDataUpdateCustomer) => {
                // console.log(_rsDataUpdateCustomer);

                if (_rsDataUpdateCustomer.status === true) {
                  getCustProfile({
                    custCode: stateRegister.dataUser.custCode,
                  }).then((_rsDataCustProfile) => {
                    // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                    document
                      .getElementsByClassName("btn-submit-otp")[0]
                      .removeAttribute("disabled");

                    if (
                      _rsDataCustProfile.status === true &&
                      _rsDataCustProfile.result.length !== 0
                    ) {
                      setDataProfile(_rsDataCustProfile.result);
                    } else {
                      console.warn(_rsDataCustProfile);
                    }
                  });
                } else {
                  console.warn(_rsDataUpdateCustomer);
                }
              });
              break;

            default:
              postUpdateCustomer(payload).then((_rsDataUpdateCustomer) => {
                // console.log(_rsDataUpdateCustomer);

                if (_rsDataUpdateCustomer.status === true) {
                  getCustProfile({
                    custCode: stateRegister.dataUser.custCode,
                  }).then((_rsDataCustProfile) => {
                    // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                    document
                      .getElementsByClassName("btn-submit-otp")[0]
                      .removeAttribute("disabled");

                    if (
                      _rsDataCustProfile.status === true &&
                      _rsDataCustProfile.result.length !== 0
                    ) {
                      setDataProfile(_rsDataCustProfile.result);
                    } else {
                      console.warn(_rsDataCustProfile);
                    }
                  });
                } else {
                  console.warn(_rsDataUpdateCustomer);
                }
              });
              break;
          }
        } else {
          /*** Register customer ***/
          payload = {
            ...payload,
            dataFrom: stateRegister.dataFrom,
          };

          if (stateRegister.dataFrom === null) {
            delete payload.dataFrom;
          }

          if (nearBranchId !== null) {
            payload = {
              ...payload,
              branchId: nearBranchId,
            };
          }

          postCreateCustomer(payload).then((_rsDataCreateCustomer) => {
            // console.log("_rsDataCreateCustomer >>", _rsDataCreateCustomer);

            document
              .getElementsByClassName("btn-submit-otp")[0]
              .removeAttribute("disabled");

            if (
              _rsDataCreateCustomer.status === true &&
              _rsDataCreateCustomer.result.length !== 0
            ) {
              getCustProfile({
                custCode: _rsDataCreateCustomer.result.custCode,
              }).then((_rsDataCustProfile) => {
                // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                document
                  .getElementsByClassName("btn-submit-otp")[0]
                  .removeAttribute("disabled");

                if (
                  _rsDataCustProfile.status === true &&
                  _rsDataCustProfile.result.length !== 0
                ) {
                  setDataProfile(_rsDataCustProfile.result);
                } else {
                  console.warn(_rsDataCustProfile);
                }
              });
            } else {
              console.warn(_rsDataCreateCustomer);
            }
          });
        }
      } else {
        console.warn(_rsDataOtpVerify);
      }
    });
  };

  const setDataProfile = (newData = {}) => {
    const tmpObj = {
      id: newData.id,
      custCode: newData.custCode,
      dataUser: newData,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const postOtpVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpVerify({ data: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpVerify resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "";

        if (data.resultCode === 40400) {
          switch (data.resultError.code) {
            case "otp_invalid":
              _title = `รหัสผิดพลาด กรุณากรอกใหม่`;
              break;

            case "otp_expired":
              _title = `รหัสหมดอายุ กรุณาส่งรหัสใหม่อีกครั้ง`;
              break;

            default:
              // _title = `${t.otpPage.modalAlertMsg.default.title}`;
              // _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

              // modalAlert.info({
              //   open: true,
              //   title: _title,
              //   subTitle: _subTitle,
              //   content: "",
              // });
              break;
          }

          if (
            data.resultError.code === "otp_invalid" ||
            data.resultError.code === "otp_expired"
          ) {
            const _form = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom"
            );
            _form[0].classList.add("error");

            const _txtError = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom .tError"
            );
            _txtError[0].textContent = _title;
          }
        }

        document
          .getElementsByClassName("btn-submit-otp")[0]
          .removeAttribute("disabled");
      }
    } catch (e) {
      console.log("ERR postOtpVerify >> ", e);
    }

    return _return;
  };

  const postUpdateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateCustomer({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postUpdateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `ยืนยันตัวตนไม่สำเร็จ`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        if (data.resultCode === 40400) {
          _title = `เกิดข้อผิดพลาด`;
        }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postUpdateCustomer >> ", e);
    }

    return _return;
  };

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  const postCreateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await createCustomer({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        const resData = data.body;
        // console.log("postCreateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postCreateCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const [isHasMyLocation, setIsHasMyLocation] = useState(null);
  const [myLocation, setMyLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [nearBranchId, setNearBranchId] = useState(null);

  const getBranch = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await branch({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getBranch resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getBranch >> ", e);
    }

    return _return;
  };

  const getLocationCallback = (position) => {
    // console.log(position);

    setMyLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    setIsHasMyLocation(true);
  };

  const getLocationErrorCallback = (error) => {
    // console.log(error);

    setIsHasMyLocation(false);
  };

  const postOtpRequest = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpRequest resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postOtpRequest >> ", e);
    }

    return _return;
  };

  const [otpToken, setOtpToken] = useState("");
  const [otpRefno, setOtpRefno] = useState("");

  const handleOtpRequest = (mobileNo) => {
    const payloadOtpRequest = {
      msisdn: mobileNo,
    };

    postOtpRequest(payloadOtpRequest).then((_rsDataOtpRequest) => {
      // console.log(_rsDataOtpRequest);

      if (
        _rsDataOtpRequest.result !== null &&
        Object.keys(_rsDataOtpRequest.result).length !== 0
      ) {
        if (
          "status" in _rsDataOtpRequest.result &&
          _rsDataOtpRequest.result.status === "success"
        ) {
          setOtpToken(_rsDataOtpRequest.result.token);
          setOtpRefno(_rsDataOtpRequest.result.refno);
        } else {
          console.warn(_rsDataOtpRequest);
        }
      } else {
        console.warn(_rsDataOtpRequest);
      }
    });
  };

  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    switch (stateRegister.prevPage) {
      case "auth-confirm":
        setStep({
          current: 3,
          max: 3,
        });
        break;

      case "register":
        setStep({
          current: 2,
          max: 2,
        });
        break;

      default:
        break;
    }

    const { dataUser } = stateRegister;
    if (Object.keys(dataUser).length !== 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          getLocationCallback,
          getLocationErrorCallback
        );
      } else {
        console.log("Geolocation is not available in your browser.");

        setIsHasMyLocation(false);
      }

      if (dataUser.mobileNo) {
        handleOtpRequest(dataUser.mobileNo);
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if ("OTPCredential" in window) {
      navigator.credentials
        .get({ otp: { transport: ["sms"] } })
        .then((otpCredential) => {
          console.log("otpCredential", otpCredential);
          // setOtp(otpCredential.code);
        })
        .catch((err) => {
          console.error("Error reading OTP:", err);
        });
    }
  }, [navigator.credentials]);

  useEffect(() => {
    // console.log(isHasMyLocation);

    if (isHasMyLocation) {
      getBranch({}).then((_rsDataBranch) => {
        // console.log("_rsDataBranch >>", _rsDataBranch);

        if (
          _rsDataBranch.status === true &&
          _rsDataBranch.result.length !== 0
        ) {
          const _length = _rsDataBranch.result.length;
          let _branch = _rsDataBranch.result;
          // console.log(isHasMyLocation, _branch);

          let _tmpBranch = [];
          for (let i = 0; i < _length; i++) {
            const _locationA = new window.google.maps.LatLng(
              myLocation.latitude,
              myLocation.longitude
            );
            const _locationB = new window.google.maps.LatLng(
              _branch[i].latitude,
              _branch[i].longitude
            );

            const _dist =
              window.google.maps.geometry.spherical.computeDistanceBetween(
                _locationA,
                _locationB
              );

            _tmpBranch[i] = {
              branchId: _branch[i].id,
              branchCode: _branch[i].code,
              distance: parseFloat((_dist / 1000).toFixed(2)), //_dist;
            };
          }

          _tmpBranch.sort((a, b) => a.distance - b.distance);

          setNearBranchId(_tmpBranch[0].branchId);
        }
      });
    }
  }, [isHasMyLocation]);

  useEffect(() => {
    // console.log(nearBranchId);
  }, [nearBranchId]);
  /* End First event render */

  useEffect(() => {
    // console.log(stateProfile);

    if (
      typeof stateProfile.dataUser.id !== "undefined" &&
      stateProfile.dataUser.id !== null
    ) {
      goNext("welcome");
    }
  }, [stateProfile]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegisterOTP">
        <main>
          <div className="wrapContent">
            <PageTitleGuest step={step} />

            <form onSubmit={handleSubmit}>
              <section id="pRegisterMain">
                <div className="bRegister">
                  <h3>
                    <img src={img_icoOtp} alt="" />
                    กรอกรหัส OTP
                  </h3>
                  <div className="bOtp">
                    <p>
                      เราเพิ่งส่งรหัส OTP ไปให้ท่านทาง
                      <br />
                      SMS ผ่านเบอร์{" "}
                      {stateRegister.dataUser.mobileNo &&
                        convertMobileFormat(
                          stateRegister.dataUser.mobileNo,
                          "-"
                        )}
                    </p>
                    <div className="bFrom">
                      {/* <div className="control-group otp">
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onPaste={handlePasteOtp}
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                      </div> */}
                      <OtpInput
                        value={otp}
                        onChange={handleChangeOtp}
                        numInputs={4}
                        separator={``}
                        containerStyle={`control-group otp`}
                        inputStyle={{
                          width: "auto",
                        }}
                      />
                      <div className="bTextSend">
                        <p>
                          ไม่ได้รับรหัส?{" "}
                          <a
                            onClick={() =>
                              stateRegister.dataUser.mobileNo
                                ? handleOtpRequest(
                                    stateRegister.dataUser.mobileNo
                                  )
                                : console.warn(stateRegister.dataUser.mobileNo)
                            }
                          >
                            ส่งรหัสใหม่อีกครั้ง
                          </a>
                        </p>
                      </div>
                      {/* <p className="tError">รหัสผิดพลาด กรุณากรอกใหม่</p> */}
                      <p className="tError"></p>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button className="btn outline" onClick={() => goBack()}>
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn btn-submit-otp"
                    disabled={otp.length < 4}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthOtp;
