import React, { useState, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardHealthService from "../../../../components/card/healthservice/CardHealthService";
import { allHealthCard } from "../../../../services/Api/Module/HealthService";
import ModalFilter from "../../../../components/modal/healthservice/catagory/ModalFilter";

import img_btnGraph from "../../../../assets/img/ci/ico-healthservice/btn-graph.png";

const HealthServiceCatagory = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [data, setData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  const [configYear, setConfigYear] = useState({
    maxYear: moment().year(),
    minYear: 2024,
  });

  const fetchGetAllHealthCard = async (payload) => {
    try {
      const response = await allHealthCard({ params: payload });
      const resData = response?.data;
      setData(resData.body);
    } catch (err) {
      console.log("err ", err);
    }
  };
  const handleFilterConfirm = ({
    year: selectedYear,
    month: selectedMonth,
  }) => {
    let payload = {
      customerId: stateProfile.id,
    };
    if (selectedYear != "" || selectedMonth != "") {
      payload.date = `${selectedYear || "0000"}-${selectedMonth || "00"}`;
    }
    fetchGetAllHealthCard(payload);
  };
  useEffect(() => {
    moment.locale("th"); // Set the locale to Thai
    let payload = {
      customerId: stateProfile.id,
    };
    fetchGetAllHealthCard(payload);
  }, []);
  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>
      <div id="pageHealthServiceCatagory">
        <main>
          <div className="wrapContent">
            <section id="pHealthServiceCatagory">
              <div className="bHead">
                <CardHealthService
                  disease={data.congenitalDiseaseTag}
                  pill={data.drugAllergyTag}
                />
              </div>
              <div className="bContent">
                {data?.healthAppointment &&
                  Object.keys(data?.healthAppointment).length > 0 && (
                    <div className="bMeeting">
                      <h2>นัดตรวจสุขภาพครั้งต่อไป</h2>
                      <div className="bMeetingDetail">
                        <div className="bDate">
                          <p className="tDate">
                            {moment(data?.healthAppointment?.appointmentDate)
                              .locale("th")
                              .format("DD/MM/YYYY")}
                          </p>
                          <p className="tTime">
                            {moment(
                              data?.healthAppointment?.appointmentStartTime,
                              "HH:mm:ss"
                            )
                              .locale("th")
                              .format("HH.mm น.")}{" "}
                            -
                            {moment(
                              data?.healthAppointment?.appointmentEndTime,
                              "HH:mm:ss"
                            )
                              .locale("th")
                              .format("HH.mm น.")}
                          </p>
                        </div>
                        <div className="bLocation">
                          <p>{data.healthAppointment.branch}</p>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="tHead">
                  <h2>บันทึกสุขภาพ</h2>
                  <p>*ข้อมูลนี้เป็นเพียงการตรวจสุขภาพเบื้องต้น</p>
                </div>
                <div className="bGraph">
                  <a
                    href="#"
                    onClick={handleGotoPage}
                    data-page="healthservice-history-graph"
                  >
                    <img src={img_btnGraph} />
                  </a>
                </div>

                <div
                  className="bFilter"
                  onClick={() => setIsFilterModalOpen(true)}
                >
                  <p className="btnFilter">
                    ทั้งหมด ({data?.healthInfo?.length})
                  </p>
                </div>
                <div
                  className={`detailGroup ${
                    data.healthInfo?.length == 0 ? "noData" : ""
                  }`}
                >
                  <div className="bDetail">
                    {data.healthInfo?.map((item, index) => (
                      <div className="bSave" key={index + 1}>
                        <div className="tDate">
                          <p>
                            บันทึกวันที่ :{" "}
                            <span>
                              {moment(item.createdOn).format("DD/MM/YYYY")}{" "}
                              {moment(item.createdOn).format("HH.mm น.")}
                              {/* {item.date} {item.time} */}
                            </span>
                          </p>
                          <a
                            onClick={() =>
                              navigate("/healthservice-history", {
                                state: { id: item.id },
                              })
                            }
                          >
                            <img
                              src="./assets/img/ci/ico-healthservice/ico-btnnext.svg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="bCal" data-state="4">
                          <div className="tCalDetail">
                            <p>ค่า BMI</p>
                            <p>{item.bmiCalc}</p>
                          </div>
                          <div className="tGraph">
                            <div className="colorGauge">
                              <div className="colorBlock">
                                <div className="color2"></div>
                                <p className="tColor2">ต่ำ</p>
                              </div>
                              <div className="colorBlock">
                                <div className="color3"></div>
                                <p className="tColor3">สมส่วน</p>
                              </div>
                              <div className="colorBlock">
                                <div className="color4"></div>
                                <p className="tColor4">น้ำหนักเกิน</p>
                              </div>
                              <div className="colorBlock">
                                <div className="color6"></div>
                                <p className="tColor6">สูง</p>
                              </div>
                            </div>
                            <div
                              className="tPoint"
                              style={{ left: `${item.bmiPercentTag}%` }}
                            ></div>
                          </div>
                          <div className="bSum">
                            <div className="tSumDetail">
                              <p className="tBold">น้ำหนักล่าสุด</p>
                              <p className="tBold">ส่วนสูงล่าสุด</p>
                              <p>{Math.round(item.weight)} กก.</p>
                              <p>{Math.round(item.height)} ซม.</p>
                            </div>
                            <div
                              className="btn outline"
                              onClick={() =>
                                navigate("/healthservice-detail", {
                                  state: { id: item.id },
                                })
                              }
                            >
                              <p>ข้อมูล BMI</p>
                              <img src="./assets/img/ci/ico-healthservice/ico-arrow-right.svg" />
                            </div>
                          </div>
                          <div className="bStatus">
                            <div
                              className="status"
                              data-status={
                                item.sbp !== null && item.dbp !== null
                                  ? item.mechanicPressureColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status1.svg`}
                                />
                              </div>
                              <p>ค่าความดัน</p>
                            </div>
                            <div
                              className="status"
                              data-status={
                                item.bloodSugar !== null
                                  ? item.mechanicSugarColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status2.svg`}
                                />
                              </div>
                              <p>ค่าน้ำตาล</p>
                            </div>
                            <div
                              className="status"
                              data-status={
                                item.visceralFat !== null
                                  ? item.mechanicVisceralFatColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status3.svg`}
                                />
                              </div>
                              <p>
                                ไขมัน
                                <br />
                                ช่องท้อง
                              </p>
                            </div>
                            <div
                              className="status"
                              data-status={
                                item.bodyFat !== null
                                  ? item.mechanicBodyFatColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status4.svg`}
                                />
                              </div>
                              <p>
                                ไขมัน
                                <br />
                                ร่างกาย
                              </p>
                            </div>

                            <div
                              className="status"
                              data-status={
                                item.muscleMass !== null
                                  ? item.mechanicMuscleMassColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status5.svg`}
                                />
                              </div>
                              <p>
                                มวล
                                <br />
                                กล้ามเนื้อ
                              </p>
                            </div>

                            <div
                              className="status"
                              data-status={
                                item.boneMass !== null
                                  ? item.mechanicBoneMassColorTag
                                  : "0"
                              }
                            >
                              <div className="bImg">
                                <img
                                  src={`./assets/img/ci/ico-healthservice/ico-status6.svg`}
                                />
                              </div>
                              <p>
                                มวล
                                <br />
                                กระดูก
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="noDataIcon">
                    <img
                      src="./assets/img/ci/ico-healthservice/ico-noDataCatagory.svg"
                      alt=""
                    />
                    <p>ไม่พบบันทึกสุขภาพ</p>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline-primary"
                  onClick={() => {
                    navigate("/healthservice-update");
                  }}
                >
                  <p>อัปเดตข้อมูลใหม่</p>
                </button>
                {/* <!-- <button className="btn" onclick="">ยืนยันข้อมูล</button> --> */}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ModalFilter
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onConfirm={handleFilterConfirm}
        maxYear={configYear.maxYear}
        minYear={configYear.minYear}
      />
    </>
  );
};

export default HealthServiceCatagory;
