import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import img_icoSuccess from "../../../assets/img/ci/icon-register/ico-success.svg";
import img_icoComplete from "../../../assets/img/ci/survey/ico-complete.svg";

const SurveyComplete = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateSurvey = useSelector((state) => state.survey);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateSurvey);
    // console.log("useEffect !", Object.getOwnPropertyNames(stateProfile.dataUser).length);

    if (
      stateProfile.id === 0 ||
      Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
      Object.getOwnPropertyNames(stateSurvey.data).length === 0
    ) {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   console.log(newSurvey);
  // }, [newSurvey]);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageSurveyComplete">
        <main>
          <div className="wrapContent">
            <section id="pCompleteMain">
              <div className="bLogo">
                <img src={img_icoSuccess} />
              </div>
              <div className="bText">
                <div className="bTitle">
                  <img src={img_icoComplete} />
                  <div>
                    <h1>แบบสำรวจเสร็จสิ้น</h1>
                    <p>ขอบคุณสำหรับความร่วมมือ!</p>
                  </div>
                </div>
                <div className="bPoint">
                  <p>
                    ได้คะแนนจากการตอบคำถาม
                    <span>
                      {stateSurvey.data?.point?.toLocaleString()} คะแนน
                    </span>
                  </p>
                </div>
                <div className="bBtn">
                  <a
                    href="#"
                    className="btn"
                    onClick={handleGotoPage}
                    data-page="profile"
                  >
                    กลับสู่หน้าโปรไฟล์
                  </a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default SurveyComplete;
