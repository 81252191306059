import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { healthInfoById } from "../../../../services/Api/Module/HealthService";

import moment from "moment";

import ModalHealthInfoFilter from "../../../../components/modal/healthservice/chart/ModalHealthInfoFilter";
import ChartHealthInfo from "../../../../components/chart/ChartHealthInfo";

import img_rateBmi from "../../../../assets/img/ci/ico-healthservice/detail-BMI.svg";
import img_rateBoneF from "../../../../assets/img/ci/ico-healthservice/detail-boneF.svg";
import img_rateBoneM from "../../../../assets/img/ci/ico-healthservice/detail-boneM.svg";
import img_rateFatF from "../../../../assets/img/ci/ico-healthservice/detail-fatF.svg";
import img_rateFatM from "../../../../assets/img/ci/ico-healthservice/detail-fatM.svg";
import img_rateMuscleF from "../../../../assets/img/ci/ico-healthservice/detail-muscleF.svg";
import img_rateMuscleM from "../../../../assets/img/ci/ico-healthservice/detail-muscleM.svg";
import img_ratePressure from "../../../../assets/img/ci/ico-healthservice/detail-pressure.svg";
import img_rateSugar from "../../../../assets/img/ci/ico-healthservice/detail-sugar.svg";
import img_rateVisceralFat from "../../../../assets/img/ci/ico-healthservice/detail-visceralFat.svg";

const HealthServiceHistoryGraph = () => {
  const dispatch = useDispatch();
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle type */
  const selectValue = {
    bmi: "ค่า BMI",
    pressure: "ค่าความดันโลหิต",
    sugar: "ค่าน้ำตาล",
    visceralFat: "ไขมันในช่องท้อง",
    fat: "ไขมันร่างกาย",
    muscle: "มวลกล้ามเนื้อ",
    bone: "มวลกระดูก",
  };

  const [typeFilter, setTypeFilter] = useState("year");
  const [filterValue, setFilterValue] = useState(moment().year());
  const [typeValue, setTypeValue] = useState("bmi");
  const [healthValue, setHealthValue] = useState(null);

  const handleOnTypeChange = (e) => {
    // console.log("handleOnTypeChange >>", e.target.value);

    setHealthValue(null);

    setTypeValue(e.target.value);
  };
  /* End Handle type */

  /* Handle graph condition */
  const handleGraphCondition = (_typeValue) => {
    let _ele = "";

    switch (_typeValue) {
      case "bmi":
        _ele = (
          <>
            <p data-ratecolor="blue">ต่ำ</p>
            <p data-ratecolor="green">สมส่วน</p>
            <p data-ratecolor="yellow">น้ำหนักเกิน</p>
            <p data-ratecolor="red">สูง</p>
          </>
        );
        break;

      case "pressure":
        _ele = (
          <>
            <p data-ratecolor="blue">ต่ำ</p>
            <p data-ratecolor="green">ระดับปกติ</p>
            <p data-ratecolor="yellow">สูง</p>
            <p data-ratecolor="red">สูงมาก</p>
          </>
        );
        break;

      case "sugar":
        _ele = (
          <>
            <p data-ratecolor="green">ปกติ</p>
            <p data-ratecolor="yellow">แนวโน้มสูง</p>
            <p data-ratecolor="red">สูง</p>
          </>
        );
        break;

      case "visceralFat":
        _ele = (
          <>
            <p data-ratecolor="green">ปกติ</p>
            <p data-ratecolor="yellow">ค่อนข้างสูง</p>
            <p data-ratecolor="orange">สูง</p>
            <p data-ratecolor="red">สูงมาก</p>
          </>
        );
        break;

      case "fat":
        _ele = (
          <>
            <p data-ratecolor="blue">ค่อนข้างน้อย</p>
            <p data-ratecolor="green">เหมาะสม</p>
            <p data-ratecolor="yellow">ค่อนข้างสูง</p>
            <p data-ratecolor="red">สูง</p>
          </>
        );
        break;

      case "muscle":
        _ele = (
          <>
            <p data-ratecolor="blue">ค่อนข้างน้อย</p>
            <p data-ratecolor="green">เหมาะสม</p>
            <p data-ratecolor="yellow">ค่อนข้างสูง</p>
            <p data-ratecolor="red">สูง</p>
          </>
        );
        break;

      case "bone":
        _ele = (
          <>
            <p data-ratecolor="blue">ดีมาก</p>
            <p data-ratecolor="green">ระดับปกติ</p>
            <p data-ratecolor="red">ต่ำ</p>
          </>
        );
        break;

      default:
        break;
    }

    return _ele;
  };
  /* End Handle graph condition */

  /* Modal filter */
  const [configYear, setConfigYear] = useState({
    maxYear: moment().year(),
    minYear: moment().year() - 2,
  });

  const [isModalShow, setIsModalShow] = useState(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  const handleOpenModalFilter = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModalFilter = () => {
    setIsModalShow(false);
  };

  const handleSubmitFilter = (dataFilter, submitFilter) => {
    if (submitFilter) {
      // console.log("dataFilter!! >>>", dataFilter, submitFilter);

      setHealthValue(null);

      setTypeFilter(dataFilter["type"]);

      switch (dataFilter["type"]) {
        case "year":
          setFilterValue(dataFilter["year"]);
          break;

        case "yearPeriod":
          setFilterValue({
            yearStart: dataFilter["yearStart"],
            yearEnd: dataFilter["yearEnd"],
          });
          break;

        case "sixMonth":
          break;

        default:
          // console.log("switch >> default");
          break;
      }

      setIsFilterSubmit(true);
    }
  };

  useEffect(() => {
    // console.log(isFilterSubmit);
  }, [isFilterSubmit]);
  /* End Modal filter */

  /* First event render */
  const getHealthInfo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await healthInfoById({
        custId: payload.custId,
        params: payload.params,
      });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getHealthInfo resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getHealthInfo >> ", e);
    }

    return _return;
  };

  const fetchData = () => {
    getHealthInfo({
      custId: stateProfile.id,
      params: {
        typeFilter: typeFilter,
        filterValue: filterValue,
        typeValue: typeValue,
      },
    }).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true && _rsData.result.length !== 0) {
        setHealthValue(_rsData.result);
      }
    });
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (
      stateProfile.id === 0 ||
      Object.keys(stateProfile.dataUser).length === 0
    ) {
      navigate("/healthservice-main");
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(typeFilter, filterValue, typeValue);
    fetchData();
  }, [typeFilter, filterValue, typeValue]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageHealthService-Graph">
        <main>
          <div className="wrapContent">
            <ModalHealthInfoFilter
              open={isModalShow}
              onClose={handleCloseModalFilter}
              maxYear={configYear.maxYear}
              minYear={configYear.minYear}
              submitCallback={handleSubmitFilter}
            />

            <section id="pTiltlePage">
              <h1>กราฟสุขภาพ</h1>
              <div className="bFilter">
                <div className="bRow">
                  <div className="control-group">
                    <div className="select">
                      <select
                        name="selectType"
                        defaultValue={typeValue}
                        onChange={handleOnTypeChange}
                      >
                        <option value="bmi">{selectValue["bmi"]}</option>
                        <option value="pressure">
                          {selectValue["pressure"]}
                        </option>
                        <option value="sugar">{selectValue["sugar"]}</option>
                        <option value="visceralFat">
                          {selectValue["visceralFat"]}
                        </option>
                        <option value="fat">{selectValue["fat"]}</option>
                        <option value="muscle">{selectValue["muscle"]}</option>
                        <option value="bone">{selectValue["bone"]}</option>
                      </select>
                    </div>
                  </div>
                  <div className="btnFilter" onClick={handleOpenModalFilter}>
                    เลือกเดือน/ปี
                  </div>
                </div>
              </div>
            </section>
            <section id="pGraph">
              <div className="bGraph">
                <div className="bTitle">
                  <h2>
                    {{
                      bmi: selectValue["bmi"],
                      pressure: selectValue["pressure"],
                      sugar: selectValue["sugar"],
                      visceralFat: selectValue["visceralFat"],
                      fat: selectValue["fat"],
                      muscle: selectValue["muscle"],
                      bone: selectValue["bone"],
                    }[typeValue] || ""}
                  </h2>
                  <p>
                    {typeFilter === "year"
                      ? filterValue
                      : typeFilter === "yearPeriod"
                      ? `${filterValue["yearStart"]} - ${filterValue["yearEnd"]}`
                      : "ย้อนหลัง 6 เดือน"}
                  </p>
                </div>
                <div className="bApi">
                  <ChartHealthInfo
                    typeValue={typeValue}
                    textValue={selectValue[typeValue]}
                    typeFilter={typeFilter}
                    filterValue={filterValue}
                    healthValue={healthValue}
                  />
                </div>
                {typeValue === "pressure" && (
                  <div className="bLineInfo">
                    <p data-linecolor="green">ความดันตัวบน</p>
                    <p data-linecolor="orange">ความดันตัวล่าง</p>
                  </div>
                )}
                <div className="bCondition">
                  {handleGraphCondition(typeValue)}
                </div>
              </div>
              <div className="bDetails">
                <h2>ตารางวัดผล</h2>
                <div className="bPic">
                  {{
                    bmi: <img src={img_rateBmi} alt="" />,
                    pressure: <img src={img_ratePressure} alt="" />,
                    sugar: <img src={img_rateSugar} alt="" />,
                    visceralFat: <img src={img_rateVisceralFat} alt="" />,
                    fat:
                      stateProfile.gender === "m" ? (
                        <img src={img_rateFatM} alt="" />
                      ) : (
                        <img src={img_rateFatF} alt="" />
                      ),
                    muscle:
                      stateProfile.gender === "m" ? (
                        <img src={img_rateMuscleM} alt="" />
                      ) : (
                        <img src={img_rateMuscleF} alt="" />
                      ),
                    bone:
                      stateProfile.gender === "m" ? (
                        <img src={img_rateBoneM} alt="" />
                      ) : (
                        <img src={img_rateBoneF} alt="" />
                      ),
                  }[typeValue] || ""}
                </div>
              </div>
            </section>
            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={handleGotoPage}
                  data-page="healthservice-catagory"
                >
                  <p>ย้อนกลับ</p>
                </button>
                {/* <!-- <button className="btn" onclick="">ยืนยันข้อมูล</button> --> */}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default HealthServiceHistoryGraph;
