import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import QRCode from "react-qr-code";
import Barcode from "react-barcode";

const CouponRedeem = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", stateProfile, stateCoupon);

    if (
      stateCoupon.id === 0 &&
      Object.getOwnPropertyNames(stateCoupon.redeem).length === 0
    ) {
      navigate("/profile");
    } else {
      toggleTimer();
    }
  }, []);
  /* End First event render */

  /* Run countdonw timer */
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const toggleTimer = () => setRunTimer((t) => !t);

  const seconds = String(parseInt(countDown % 60)).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    let timerInterval;

    if (runTimer) {
      setCountDown(60 * stateCoupon.redeem.expiredIn);
      // setCountDown(60 * 0.1);

      timerInterval = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("timeout >> expired");

      setCountDown(0);
      setRunTimer(false);
      setIsExpired(true);
    }
  }, [countDown, runTimer, isExpired]);
  /* End Run countdonw timer */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCoupon-Scan">
        <main>
          <div className="wrapContent">
            <section id="pCouponDetails">
              <section>
                <div className="bTitle">
                  <h1>{stateCoupon.data.name}</h1>
                  <p>ยอดซื้อขั้นต่ำ ฿{stateCoupon.data.minTotal}.-</p>
                </div>
              </section>
              <div className="bCouponDetails">
                <div className="bPic">
                  <h2>Scan QR หรือ Barcode เพื่อรับสิทธิ์</h2>
                  {stateCoupon.redeem.code && (
                    <QRCode
                      size={168}
                      value={stateCoupon.redeem.code}
                      viewBox={`0 0 168 168`}
                    />
                  )}
                  <p className="code">
                    รหัสคูปอง :{" "}
                    {stateCoupon.redeem.code ? stateCoupon.redeem.code : ""}
                  </p>
                  <div className="bBarcode">
                    <p>กรุณาให้พนักงานแสกนที่หน้าร้านเท่านั้น</p>
                    {stateCoupon.redeem.code && (
                      <Barcode
                        displayValue={false}
                        value={stateCoupon.redeem.code}
                        width={2}
                        height={55}
                      />
                    )}
                  </div>
                </div>
                {isExpired === true ? (
                  <div className="bTimeout">
                    <p>สิ้นสุดระยะเวลาใช้งาน Barcode</p>
                  </div>
                ) : (
                  <div className="bTime">
                    <div>หมดอายุใน</div>
                    <div>
                      <span>{minutes}</span> นาที
                    </div>
                    <div>
                      <span>{seconds}</span> วินาที
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponRedeem;
