import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";

const ChartHealthInfo = ({
  typeValue,
  textValue,
  typeFilter, // "year", "yearPeriod", "sixMonth"
  filterValue,
  healthValue,
}) => {
  const [data, setData] = useState([]);

  const [moreOption, setMoreOption] = useState({});
  const [viewWindow, setViewWindow] = useState({});
  const [ticks, setTicks] = useState([]);

  const options = {
    // title: "...",
    curveType: "function",
    legend: { position: "none" },
    colors: ["#4BC0C0"],
    vAxis: {
      // title: "...",
      minValue: 0,
    },
    pointSize: 5,
    ...moreOption,
  };

  useEffect(() => {
    // console.log(
    //   typeValue,
    //   textValue,
    //   typeFilter, // "year", "yearPeriod", "sixMonth"
    //   filterValue,
    //   healthValue
    // );

    switch (typeFilter) {
      case "year":
        // const currentYear = filterValue;

        // setViewWindow({
        //   min: new Date(currentYear, 0, 1), // Start of January
        //   max: new Date(currentYear, 11, 31), // End of December
        // });

        // setTicks([
        //   new Date(currentYear, 0, 1), // January
        //   new Date(currentYear, 1, 1), // February
        //   new Date(currentYear, 2, 1), // March
        //   new Date(currentYear, 3, 1), // April
        //   new Date(currentYear, 4, 1), // May
        //   new Date(currentYear, 5, 1), // June
        //   new Date(currentYear, 6, 1), // July
        //   new Date(currentYear, 7, 1), // August
        //   new Date(currentYear, 8, 1), // September
        //   new Date(currentYear, 9, 1), // October
        //   new Date(currentYear, 10, 1), // November
        //   new Date(currentYear, 11, 1), // December
        // ]);

        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "MMM",
              // viewWindow: viewWindow,
              ticks: ticks,
            },
            vAxes: {
              0: {
                // title: ""
              },
              1: {
                // title: ""
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataYear = [[typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"]];
            let _tmpTicks = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataYear = [
                ..._tmpDataYear,
                [
                  new Date(yyyy, mm, dd),
                  healthValue[i].sbpValue,
                  healthValue[i].dbpValue,
                ],
              ];

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: mm, //moment(healthValue[i].date).format("MMM"),
              });
            }

            setData(_tmpDataYear);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "MMM",
              // viewWindow: viewWindow,
              ticks: ticks,
            },
          });

          if (healthValue !== null) {
            let _tmpDataYear = [[typeFilter, textValue]];
            let _tmpTicks = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataYear = [
                ..._tmpDataYear,
                [new Date(yyyy, mm, dd), healthValue[i].value],
              ];

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: mm, //moment(healthValue[i].date).format("MMM"),
              });
            }

            setData(_tmpDataYear);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      case "yearPeriod":
        // setViewWindow({
        //   min: new Date(filterValue.yearStart, 0, 1), // Start year
        //   max: new Date(filterValue.yearEnd, 11, 31), // End year
        // });

        // let _tmpTicks = [];
        // for (let i = filterValue.yearStart; i <= filterValue.yearEnd; i++) {
        //   _tmpTicks = [
        //     ..._tmpTicks,
        //     new Date(i, 0, 1), // January
        //     new Date(i, 1, 1), // February
        //     new Date(i, 2, 1), // March
        //     new Date(i, 3, 1), // April
        //     new Date(i, 4, 1), // May
        //     new Date(i, 5, 1), // June
        //     new Date(i, 6, 1), // July
        //     new Date(i, 7, 1), // August
        //     new Date(i, 8, 1), // September
        //     new Date(i, 9, 1), // October
        //     new Date(i, 10, 1), // November
        //     new Date(i, 11, 1), // December
        //   ];
        // }
        // setTicks(_tmpTicks);

        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "YYYY",
              // slantedText: true,
              // slantedTextAngle: 45,
              // viewWindow,
              ticks: ticks,
            },
            vAxes: {
              0: {
                // title: ""
              },
              1: {
                // title: ""
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataYearPeriod = [
              [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ];
            let _tmpTicks = [];
            let _tmpYear = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataYearPeriod = [
                ..._tmpDataYearPeriod,
                [
                  new Date(yyyy, mm, dd),
                  healthValue[i].sbpValue,
                  healthValue[i].dbpValue,
                ],
              ];

              if (typeof _tmpYear.find((y) => y === yyyy) === "undefined") {
                _tmpYear.push(yyyy);
              } else {
                _tmpYear.push("");
              }

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: _tmpYear[i],
              });
            }

            setData(_tmpDataYearPeriod);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "YYYY",
              // slantedText: true,
              // slantedTextAngle: 45,
              // viewWindow,
              ticks: ticks,
            },
          });

          if (healthValue !== null) {
            let _tmpDataYearPeriod = [[typeFilter, textValue]];
            let _tmpTicks = [];
            let _tmpYear = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataYearPeriod = [
                ..._tmpDataYearPeriod,
                [new Date(yyyy, mm, dd), healthValue[i].value],
              ];

              if (typeof _tmpYear.find((y) => y === yyyy) === "undefined") {
                _tmpYear.push(yyyy);
              } else {
                _tmpYear.push("");
              }

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: _tmpYear[i],
              });
            }

            setData(_tmpDataYearPeriod);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      case "sixMonth":
        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "MMM",
              // viewWindow: viewWindow,
              ticks: ticks,
            },
            vAxes: {
              0: {
                // title: ""
              },
              1: {
                // title: ""
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataSixMonth = [[typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"]];
            let _tmpTicks = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataSixMonth = [
                ..._tmpDataSixMonth,
                [
                  new Date(yyyy, mm, dd),
                  healthValue[i].sbpValue,
                  healthValue[i].dbpValue,
                ],
              ];

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: mm, //moment(healthValue[i].date).format("MMM"),
              });
            }

            setData(_tmpDataSixMonth);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "MMM",
              // viewWindow: viewWindow,
              ticks: ticks,
            },
          });

          if (healthValue !== null) {
            let _tmpDataSixMonth = [[typeFilter, textValue]];
            let _tmpTicks = [];

            for (let i = 0; i < Object.keys(healthValue).length; i++) {
              const date = new Date(healthValue[i].date);
              const yyyy = date.getFullYear();
              const mm = date.getMonth();
              const dd = date.getDate();

              _tmpDataSixMonth = [
                ..._tmpDataSixMonth,
                [new Date(yyyy, mm, dd), healthValue[i].value],
              ];

              _tmpTicks.push({
                v: new Date(yyyy, mm, dd),
                f: mm, //moment(healthValue[i].date).format("MMM"),
              });
            }

            setData(_tmpDataSixMonth);

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      default:
        break;
    }
  }, [filterValue, healthValue]);

  useEffect(() => {
    // console.log("data", data, moreOption);
  }, [data, moreOption]);

  return (
    <>
      <Chart
        chartType="LineChart"
        width="100%"
        height="100%"
        data={data}
        options={options}
        language="th"
        chartLanguage="th"
        loader={<div>Loading...</div>}
      />
    </>
  );
};

export default ChartHealthInfo;
