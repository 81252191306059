import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { custProfile } from "../../../services/Api/Module/Customer";
const CardHealthService = ({ disease = [], pill = [] }) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [isHidden, setIsHidden] = useState(false);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };
  function calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    // ตรวจสอบว่าครบวันเกิดหรือยัง
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  }
  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };
  const dropdownClass = `bDropdownDetail ${
    (disease.length > 0 || pill.length > 0) && !isHidden ? "" : "hide"
  }`;
  const arrowClass = `arrow ${
    (disease.length > 0 || pill.length > 0) && !isHidden ? "" : "upArrow"
  }`;
  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      getCustProfile({
        custCode: stateProfile.custCode,
      }).then((_rsDataCustProfile) => {
        if (
          _rsDataCustProfile.status === true &&
          _rsDataCustProfile.result.length !== 0
        ) {
          setDataProfile(
            {
              ...stateProfile.dataUser,
              ..._rsDataCustProfile.result,
            },
            true
          );
        }
      });
    }
  }, []);

  return (
    <div className="bCardContent">
      <div className="bCard-healthservice">
        <div className="bDetail">
          <h2>บัตรสุขภาพประจำตัว</h2>
          <div className="tDetail">
            <div className="bGroupName">
              <p>ชื่อ - นามสกุล</p>
              <p className="tBold">
                {typeof stateProfile.dataUser.firstname !== "undefined" &&
                  typeof stateProfile.dataUser.lastname !== "undefined" &&
                  `${stateProfile.dataUser.firstname} ${stateProfile.dataUser.lastname}`}
              </p>
            </div>
            <div className="bGroup">
              <p>เพศ</p>
              <p>อายุ</p>
              <p className="tBold">
                {" "}
                {stateProfile.dataUser.gender === "m"
                  ? "ชาย"
                  : stateProfile.dataUser.gender === "f"
                  ? "หญิง"
                  : stateProfile.dataUser.gender === "n/a"
                  ? "ไม่ระบุ"
                  : ""}
              </p>
              <p className="tBold">
                {calculateAge(stateProfile.dataUser.birthDate)} ปี
              </p>
            </div>
          </div>
        </div>
        <div className="bLink btnDropdown">
          <p className={arrowClass} onClick={toggleHidden}>
            โรคประจำตัว/แพ้ยา
          </p>
        </div>
      </div>
      <div className={dropdownClass}>
        {disease.length <= 0 ? (
          <></>
        ) : (
          <div className="groupCardContent">
            <p className="tHead">โรคประจำตัว</p>
            <div className="contentGroup">
              {disease.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        )}
        {pill.length <= 0 ? (
          ""
        ) : (
          <div className="groupCardContent">
            <p className="tHead">ประวัติแพ้ยา</p>
            <div className="contentGroup">
              {pill.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardHealthService;
