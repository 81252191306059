import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const ModalFilter = ({
  open = false,
  onClose = () => {},
  maxYear = "",
  minYear = "",
  onConfirm = () => {},
}) => {
  const [selectedYear, setSelectedYear] = useState(maxYear);
  const [selectedMonth, setSelectedMonth] = useState("");

  let optionsYear = [];

  if (minYear !== "" && maxYear !== "") {
    for (let i = maxYear; i >= minYear; i--) {
      optionsYear.push({
        value: i,
        label: i,
      });
    }
  }
  const handleResetFilter = () => {
    setSelectedYear("");
    setSelectedMonth("");
  };

  const handleConfirm = () => {
    // console.log("year", selectedYear);
    // console.log("year", selectedMonth);
    onConfirm({ year: selectedYear, month: selectedMonth });
    onClose();
  };
  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div
        className="bModal bCard"
        id="ModalFilter"
        style={{ display: "block" }}
      >
        <div className="btnClose btnCloseModal" onClick={onClose}>
          <img src="./assets/img/ci/icon-modal/ico-xmark-healthservice.svg" />
        </div>
        <div className="bInner">
          <div className="bTitle">
            <span>กรองข้อมูลโดย</span>
          </div>
          <div className="bFilterForm">
            <div className="bForm warranty">
              <div className="bRow">
                <div className="control-group">
                  <div className="tTitle">ปี (ค.ศ.)</div>
                  <div className="select">
                    <select
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      <option name="year" value="">
                        ทั้งหมด
                      </option>
                      {optionsYear.map(({ value, label }, index) => (
                        <option key={index + 1} value={value}>
                          {parseInt(label)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label htmlFor="" className="tError">
                    กรุณาเลือกปี
                  </label>
                </div>
              </div>
              <div className="bRow">
                <div className="control-group">
                  <div className="tTitle">เดือน</div>
                  <div className="select">
                    <select
                      name="month"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="01">มกราคม</option>
                      <option value="02">กุมภาพันธ์</option>
                      <option value="03">มีนาคม</option>
                      <option value="04">เมษายน</option>
                      <option value="05">พฤษภาคม</option>
                      <option value="06">มิถุนายน</option>
                      <option value="07">กรกฎาคม</option>
                      <option value="08">สิงหาคม</option>
                      <option value="09">กันยายน</option>
                      <option value="10">ตุลาคม</option>
                      <option value="11">พฤศจิกายน</option>
                      <option value="12">ธันวาคม</option>
                    </select>
                  </div>
                  <label htmlFor="" className="tError">
                    กรุณาเลือกเดือน
                  </label>
                </div>
              </div>
            </div>
            <div className="bBtn">
              <button className="btn" onClick={handleConfirm}>
                ยืนยัน
              </button>
              <button
                className="btn outline btnClose"
                onClick={handleResetFilter}
              >
                รีเซ็ท
              </button>
            </div>
          </div>
        </div>
        <a className="btnCloseModalIcon"></a>
      </div>
    </Dialog>
  );
};

export default ModalFilter;
