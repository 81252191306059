import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon } from "../../../../features/CouponSlice";

import moment from "moment";

import img_icoTicket from "../../../../assets/img/ci/ico-coupon/ico-ticket.svg";

const CardCoupon = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataCoupon = (newData) => {
    const tmpObj = { id: newData.id, data: newData, redeem: {} };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto coupon detail page */
  const navigate = useNavigate();

  const handleGotoCouponDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoCouponDetail >>", props.dataCoupon);

    setDataCoupon(props.dataCoupon);

    navigate("/coupon/detail");
  };
  /* End Handle goto coupon detail page */

  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  /* Check image exists */
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    // console.log("props", props);

    const img = new Image();
    img.src = props.dataCoupon.thumbnailImg;

    img.onload = () => {
      setImageExists(true);
    };

    // img.onerror = () => {
    //   setImageExists(false);
    // };
  }, [props.dataCoupon.thumbnailImg]);
  /* End Check image exists */

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article
      ref={eleRef}
      data-id={props.dataCoupon.id}
      data-type={props.dataCoupon.type}
      data-startdate={props.dataCoupon.startDate}
      data-enddate={props.dataCoupon.endDate}
    >
      <div className="bPic">
        {imageExists ? (
          <img src={props.dataCoupon.thumbnailImg} />
        ) : (
          <img src={img_icoTicket} />
        )}
      </div>
      <div className="bDetails">
        <div className="bValue">
          <p>{props.dataCoupon.name}</p>
          <div className="bDate">
            วันนี้ -{" "}
            {/* {moment(props.dataCoupon.endDate).format("DD/MM/YY")} */}
            {moment(props.dataCoupon.endDate).format("DD/MM/") +
              (parseInt(moment(props.dataCoupon.endDate).format("YYYY")) + 543)
                .toString()
                .slice(-2)}
          </div>
        </div>
        <div className="bPoint">
          <div className="bBtn">
            <a href="#" onClick={handleGotoCouponDetail}>
              {props.dataCoupon.point === 0
                ? `ฟรี`
                : `ใช้ ${props.dataCoupon.point} คะแนน`}
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardCoupon;
