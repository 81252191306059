import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";

import { setProfile } from "../../../features/ProfileSlice";
import { custProfile, custSurvey } from "../../../services/Api/Module/Customer";

import moment from "moment";

// import QRCode from "react-qr-code";
import Barcode from "react-barcode";

import PageTitleMember from "../../../layouts/inc/PageTitleMember";

import MenuMember from "../../../components/menu/MenuMember";

import Helper from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import ModalProfileQrCode from "../../../components/modal/account/profile/ModalProfileQrCode";

import img_icoLogo from "../../../assets/img/ci/icon-profile/ico-logo.svg";
import img_icoPoint from "../../../assets/img/ci/icon-profile/ico-point.svg";
// import img_icoQr from "../../../assets/img/ci/icon-profile/ico-qr.svg";
// import img_icoSearchMini from "../../../assets//img/ci/icon-profile/ico-searchMini.svg";
// import img_icoEdit from "../../../assets/img/ci/icon-profile/ico-edit.svg";

import PageLoader from "../../../components/loader/PageLoader";

const MyPoint = ({ from, to }) => {
  // console.log(from, to);

  const { number } = useSpring({
    from: { number: from },
    number: to,
    delay: 160,
    config: { mass: 1, tension: 20, friction: 8 },
  });

  return (
    <animated.span>
      {number.to((to) => Helper.FN.toNumberWithCommas(to.toFixed(0)))}
    </animated.span>
  );
};

const Profile = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData, newSurvey = 0, newSurveyPoint = 0) => {
    const tmpObj = {
      dataUser: newData,
      newSurvey: newSurvey,
      newSurveyPoint: newSurveyPoint,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const [oldPoint] = useState(
    stateProfile.dataUser.currentPoint
      ? parseInt(stateProfile.dataUser.currentPoint)
      : 0
  );

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  const getSurvey = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custSurvey({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSurvey resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getSurvey >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);
    // console.log("useEffect !", Object.getOwnPropertyNames(stateProfile.dataUser).length);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      getCustProfile({
        custCode: stateProfile.custCode,
      }).then((_rsDataCustProfile) => {
        // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

        if (
          _rsDataCustProfile.status === true &&
          _rsDataCustProfile.result.length !== 0
        ) {
          setDataProfile({
            ...stateProfile.dataUser,
            ..._rsDataCustProfile.result,
          });

          getSurvey({
            custId: stateProfile.id,
            lineUid: stateProfile.lineUid,
          }).then((_rsDataSurvey) => {
            // console.log("_rsDataSurvey >>", _rsDataSurvey);

            if (
              _rsDataSurvey.status === true &&
              _rsDataSurvey.result.length !== 0
            ) {
              setDataProfile(
                {
                  ...stateProfile.dataUser,
                  ..._rsDataCustProfile.result,
                },
                _rsDataSurvey.result.availableSurvey.toLocaleString(),
                _rsDataSurvey.result.totalPoint.toLocaleString()
              );
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // console.log(stateProfile);
  }, [stateProfile, oldPoint]);
  /* End First event render */

  /* Modal profile qrcode */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModal = () => {
    setIsModalShow(false);
  };
  /* End Modal profile qrcode */

  return (
    <>
      {Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ? (
        <>
          <PageLoader />
        </>
      ) : (
        <>
          <Helmet>
            <title>Health Up</title>
            <meta name="title" content="Health Up" />
            <meta name="description" content="" />
            <meta name="keyword" content="" />
            <meta property="og:title" content="Health Up" />
            <meta property="og:description" content="" />
            <meta property="og:image" content="" />
            <meta property="og:url" content="" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Health Up" />
          </Helmet>

          <div id="pageProfile">
            <main>
              <div className="wrapContent">
                <ModalProfileQrCode
                  open={isModalShow}
                  onClose={handleCloseModal}
                  custCode={stateProfile.custCode}
                />

                <div className="cardContent">
                  <PageTitleMember title="โปรไฟล์สมาชิก" />

                  <section id="bMembercardMain">
                    <div className="bMembercard">
                      <div className="bCardMain">
                        <div className="bCard">
                          <div className="bFront">
                            <div className="bInfo">
                              <div className="bText">
                                <div className="userInfo">
                                  <div className="userNum">
                                    <img src={img_icoLogo} alt="" />
                                    <div>
                                      <p className="tType">
                                        NO. {stateProfile.custCode}
                                      </p>
                                      <p className="tName">
                                        {stateProfile.dataUser.tierName}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="bPoint">
                                    <div>
                                      <p>คะแนนของคุณ</p>
                                    </div>
                                    <div className="inlineIco">
                                      <img src={img_icoPoint} alt="" />
                                      <p>
                                        {stateProfile.dataUser.currentPoint &&
                                        parseInt(
                                          stateProfile.dataUser.currentPoint
                                        ) !== 0 ? (
                                          <MyPoint
                                            from={oldPoint}
                                            to={
                                              stateProfile.dataUser.currentPoint
                                                ? parseInt(
                                                    stateProfile.dataUser
                                                      .currentPoint
                                                  )
                                                : 0
                                            }
                                          />
                                        ) : (
                                          0
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="bQrZoom">
                                  <div
                                    className="tierImg"
                                    data-tier={
                                      typeof stateProfile.dataUser.tier !==
                                        "undefined" &&
                                      stateProfile.dataUser.tier + 1
                                    }
                                  >
                                    <div className="tTier">
                                      <p>{stateProfile.dataUser.tierName}</p>
                                    </div>
                                  </div>

                                  <div
                                    className="zoomQr"
                                    onClick={handleGotoPage}
                                    data-page="tier"
                                  >
                                    <p>รายละเอียดสิทธิพิเศษ</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="bBarcodeMain">
                    <div className="bBarcode">
                      <p>แสกนบาร์โค้ดรับแต้ม</p>
                      {stateProfile.custCode && (
                        <Barcode
                          displayValue={false}
                          value={stateProfile.custCode}
                          width={2}
                          height={55}
                        />
                      )}
                      <p
                        className="openQrcode btnConfirm"
                        onClick={handleOpenModal}
                      >
                        คลิกเพื่อแสดง QR code
                      </p>
                    </div>
                    <div className="tBarcode">
                      {stateProfile.dataUser.pointExpiredAt &&
                        stateProfile.dataUser.pointExpiredAt.length !== 0 && (
                          <div className="tExp">
                            <p>
                              {parseInt(
                                stateProfile.dataUser.pointExpiredAt[0].point
                              ).toLocaleString()}{" "}
                              คะแนนใกล้หมดอายุ
                            </p>
                            <p>
                              {" "}
                              วันหมดอายุ{" "}
                              {moment(
                                stateProfile.dataUser.pointExpiredAt[0]
                                  .expiredAt
                              ).format("DD/MM/") +
                                (parseInt(
                                  moment(
                                    stateProfile.dataUser.pointExpiredAt[0]
                                      .expiredAt
                                  ).format("YYYY")
                                ) +
                                  543)}
                            </p>
                          </div>
                        )}
                      {/* <p>
                    50 คะแนนใกล้หมดอายุ
                    <br />
                    <span> วันหมดอายุ 31/02/ 24</span>
                  </p> */}
                      <div
                        className="collectPoint btn outline card"
                        onClick={handleGotoPage}
                        data-page="earn-point-history"
                      >
                        <p>ประวัติสะสมคะแนน</p>
                      </div>
                    </div>
                  </section>
                </div>

                <section id="pSurvey">
                  <div className="bTitle">
                    <h2>ร่วมทำแบบสอบถาม รับแต้มพิเศษ!</h2>
                  </div>
                  <div className="bDetails">
                    <p>
                      แบบสอบถามใหม่ : <span>{stateProfile.newSurvey}</span>
                    </p>
                    <p>
                      คะแนนที่จะได้รับ :{" "}
                      <span>{stateProfile.newSurveyPoint} คะแนน</span>
                    </p>
                  </div>
                  <div className="bBtn">
                    <a
                      href="#"
                      className="btn"
                      onClick={handleGotoPage}
                      data-page="survey"
                    >
                      <i></i>ทำแบบสอบถาม
                    </a>
                  </div>
                </section>

                <section id="pProfileMain">
                  <div className="bProfile">
                    <div className="bGroup">
                      <div className="bDetail">
                        <div className="bRow">
                          <div className="bHead">
                            <p>ข้อมูลสมาชิก</p>
                            <a
                              href="#"
                              onClick={handleGotoPage}
                              data-page="edit-profile"
                            >
                              แก้ไข
                            </a>
                          </div>
                        </div>
                        <div className="bRow">
                          <p>รหัสสมาชิก:</p>
                          <p>{stateProfile.custCode}</p>
                        </div>
                        <div className="bRow">
                          <p>ชื่อ - นามสกุล:</p>
                          <p>
                            {typeof stateProfile.dataUser.firstname !==
                              "undefined" &&
                              typeof stateProfile.dataUser.lastname !==
                                "undefined" &&
                              `${stateProfile.dataUser.firstname} ${stateProfile.dataUser.lastname}`}
                          </p>
                        </div>
                        <div className="bRow">
                          <p>เพศ:</p>
                          <p>
                            {stateProfile.dataUser.gender === "m"
                              ? "ชาย"
                              : stateProfile.dataUser.gender === "f"
                              ? "หญิง"
                              : stateProfile.dataUser.gender === "n/a"
                              ? "ไม่ระบุ"
                              : ""}
                          </p>
                        </div>
                        <div className="bRow">
                          <p>วัน/เดือน/ปี เกิด:</p>
                          <p>
                            {/* {stateProfile.dataUser.birthDate
                          ? convertDateFormat(
                              stateProfile.dataUser.birthDate,
                              "d/m/y"
                            )
                          : ""} */}
                            {stateProfile.dataUser.birthDate &&
                              moment(stateProfile.dataUser.birthDate).format(
                                "DD/MM/"
                              ) +
                                (parseInt(
                                  moment(
                                    stateProfile.dataUser.birthDate
                                  ).format("YYYY")
                                ) +
                                  543)}
                          </p>
                        </div>
                        <div className="bRow">
                          <p>ประเภทสมาชิก:</p>
                          <p>สมาชิก HUG Club</p>
                        </div>
                        {/* <div className="bBtn">
                      <a
                        href="#"
                        onClick={handleGotoPage}
                        data-page="privacy-policy"
                      >
                        นโยบายความเป็นส่วนตัว
                      </a>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <img src="./assets/img/ci/icon-profile/tier/ico-tier5.svg"/> */}
                </section>

                <MenuMember
                  pageActive="profile"
                  newSurvey={stateProfile.newSurvey}
                  newSurveyPoint={stateProfile.newSurveyPoint}
                />
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
