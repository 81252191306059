import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import QRCode from "react-qr-code";
import Barcode from "react-barcode";

import moment from "moment";

const CouponHistoryRedeem = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", stateProfile, stateCoupon);

    if (
      stateCoupon.id === 0 &&
      Object.getOwnPropertyNames(stateCoupon.redeem).length === 0
    ) {
      navigate("/profile");
    } else {
      //
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCouponNotUse">
        <main>
          <div className="wrapContent">
            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bPic">
                  <div className="bHead">
                    <h1>คูปองนี้ยังไม่ถูกใช้</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${stateCoupon.data.name}<br/>ยอดซื้อขั้นต่ำ ฿
                      ${stateCoupon.data.minTotal}.-`,
                      }}
                    ></p>
                  </div>
                  <p className="tDetail">
                    สามารถใช้งานได้จนถึงวันที่{" "}
                    {/*moment(stateCoupon.redeem.expiredAt).format("DD/MM/YY")*/}
                    {moment(stateCoupon.redeem.expiredAt).format("DD/MM/") +
                      (
                        parseInt(
                          moment(stateCoupon.redeem.expiredAt).format("YYYY")
                        ) + 543
                      )
                        .toString()
                        .slice(-2) +
                      " " +
                      moment(stateCoupon.redeem.expiredAt).format("HH:mm:SS")}
                  </p>
                  <h2>Scan QR หรือ Barcode เพื่อรับสิทธิ์</h2>
                  {stateCoupon.redeem.code && (
                    <QRCode
                      size={168}
                      value={stateCoupon.redeem.code}
                      viewBox={`0 0 168 168`}
                    />
                  )}
                  <p className="code">
                    รหัสคูปอง :{" "}
                    {stateCoupon.redeem.code ? stateCoupon.redeem.code : ""}
                  </p>
                  <div className="bBarcode">
                    <p>กรุณาให้พนักงานแสกนที่หน้าร้านเท่านั้น</p>
                    {stateCoupon.redeem.code && (
                      <Barcode
                        displayValue={false}
                        value={stateCoupon.redeem.code}
                        width={2}
                        height={55}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponHistoryRedeem;
