import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { healthCardById } from "../../../../services/Api/Module/HealthService";
import moment from "moment";
import { Helmet } from "react-helmet";

const HealthServiceHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const stateProfile = useSelector((state) => state.profile);
  const [data, setData] = useState();
  const fetchGetHealthCardById = async (id, payload) => {
    try {
      const response = await healthCardById({ cardId: id, params: payload });
      const resData = response?.data;
      setData(resData.body);
    } catch (err) {
      console.log("err ", err);
    }
  };

  const isEmptyData = () => {
    return (
      (data?.mechanicPressure.percentTag == 0 ||
        data?.mechanicPressure?.percentTag == null) &&
      (data?.mechanicSugar.percentTag == 0 ||
        data?.mechanicSugar?.percentTag == null) &&
      (data?.mechanicBodyFat.percentTag == 0 ||
        data?.mechanicBodyFat?.percentTag == null) &&
      (data?.mechanicVisceralFat?.percentTag == 0 ||
        data?.mechanicVisceralFat?.percentTag == null) &&
      (data?.mechanicMuscleMass?.percentTag == 0 ||
        data?.mechanicMuscleMass?.percentTag == null) &&
      (data?.mechanicBoneMass?.percentTag == 0 ||
        data?.mechanicBoneMass?.percentTag == null)
    );
  };

  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    }
    let payload = {
      customerId: stateProfile.id,
    };
    fetchGetHealthCardById(id, payload);
  }, []);
  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>
      <div id="pageHealthServiceHistory">
        <main>
          <div className="wrapContent">
            <section id="pHealthServiceHistory">
              <div className="bHead">
                <h1>ประวัติการบันทึกสุขภาพ</h1>
                <p>
                  {moment(data?.healthInfo?.createdOn)
                    .locale("th")
                    .format("DD/MM/YYYY HH.mm น.")}
                </p>
              </div>
              <div className="bContent">
                <div className="tHead">
                  <p>*ข้อมูลนี้เป็นเพียงการตรวจสุขภาพเบื้องต้น</p>
                </div>
                <div className="bDetail">
                  <div className="bSave">
                    <div className="bCal">
                      <div className="tCalDetail">
                        <p>ค่า BMI</p>
                        <p>{data?.healthInfo?.bmiCalc}</p>
                      </div>
                      <div className="tGraph">
                        <div className="colorGauge">
                          <div className="colorBlock">
                            <div className="color2"></div>
                            <p className="tColor2">ต่ำ</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color3"></div>
                            <p className="tColor3">สมส่วน</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color4"></div>
                            <p className="tColor4">น้ำหนักเกิน</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color6"></div>
                            <p className="tColor6">สูง</p>
                          </div>
                        </div>
                        <div
                          className="tPoint"
                          style={{ left: `${data?.mechanicBmi?.percentTag}%` }}
                        ></div>
                      </div>
                      <div className="bSum">
                        <div className="tSumDetail">
                          <p className="tBold">น้ำหนักล่าสุด</p>
                          <p className="tBold">ส่วนสูงล่าสุด</p>
                          <p>{Math.round(data?.healthInfo?.weight)} กก.</p>
                          <p>{Math.round(data?.healthInfo?.height)} ซม.</p>
                        </div>
                        <a
                          onClick={() =>
                            navigate("/healthservice-detail", {
                              state: { id: id },
                            })
                          }
                          className="btn outline"
                        >
                          <p>ข้อมูล BMI</p>
                          <img src="./assets/img/ci/ico-healthservice/ico-arrow-right.svg" />
                        </a>
                      </div>
                      <div className="bStatus">
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.sbp !== null && data?.healthInfo?.dbp !== null
                              ? data?.mechanicPressure?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status1.svg" />
                          </div>
                          <p>ความดัน</p>
                        </div>
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.bloodSugar !== null
                              ? data?.mechanicSugar?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status2.svg" />
                          </div>
                          <p>ค่าน้ำตาล</p>
                        </div>
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.visceralFat !== null
                              ? data?.mechanicVisceralFat?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status3.svg" />
                          </div>
                          <p>
                            ไขมัน
                            <br /> ช่องท้อง
                          </p>
                        </div>
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.bodyFat !== null
                              ? data?.mechanicBodyFat?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status4.svg" />
                          </div>
                          <p>
                            ไขมัน
                            <br /> ร่างกาย
                          </p>
                        </div>
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.muscleMass !== null
                              ? data?.mechanicMuscleMass?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status5.svg" />
                          </div>
                          <p>
                            มวล
                            <br /> กล้ามเนื้อ
                          </p>
                        </div>
                        <div
                          className="status"
                          data-status={
                            data?.healthInfo?.boneMass !== null
                              ? data?.mechanicBoneMass?.colorTag
                              : "0"
                          }
                        >
                          <div className="bImg">
                            <img src="./assets/img/ci/ico-healthservice/ico-status6.svg" />
                          </div>
                          <p>
                            มวล
                            <br /> กระดูก
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`bHealthData ${isEmptyData() ? "hide" : ""}`}>
                    <div className="bAccord">
                      <div className="btnAccord">ข้อมูลสุขภาพ</div>
                      <div className="contentAccord">
                        {data?.mechanicPressure.percentTag == 0 ||
                        data?.mechanicPressure?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym1">ระดับความดัน</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/pressure", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>
                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color2"></div>
                                  <p className="tColor2">ต่ำ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">ปกติ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color4"></div>
                                  <p className="tColor4">สูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">สูงมาก</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicPressure?.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {parseInt(data?.healthInfo?.sbp)}/
                                {parseInt(data?.healthInfo?.dbp)} มม.ปรอท
                              </p>
                              <p>{data?.mechanicPressure?.label}</p>
                            </div>
                            <div className="bCalSum">
                              <p style={{ fontWeight: 500 }}>ความดันตัวบน</p>
                              <p className="tBold">
                                {parseInt(data?.healthInfo?.sbp)} มม.ปรอท
                              </p>
                              <p style={{ fontWeight: 500 }}>ความดันตัวล่าง</p>
                              <p className="tBold">
                                {parseInt(data?.healthInfo?.dbp)} มม.ปรอท
                              </p>
                            </div>
                          </div>
                        )}
                        {data?.mechanicSugar.percentTag == 0 ||
                        data?.mechanicSugar?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym2">ระดับน้ำตาล</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/sugar", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>

                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">ปกติ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color4"></div>
                                  <p className="tColor4">แนวโน้มสูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">สูง</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicSugar.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {data?.healthInfo?.bloodSugar} มก. / ดล.
                              </p>
                              <p>{data?.mechanicSugar?.label}</p>
                            </div>
                          </div>
                        )}
                        {data?.mechanicBodyFat.percentTag == 0 ||
                        data?.mechanicBodyFat?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym3">% ไขมันในร่างกาย</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/fat", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>

                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color2"></div>
                                  <p className="tColor2">ค่อนข้างน้อย</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">เหมาะสม</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color4"></div>
                                  <p className="tColor4">ค่อนข้างสูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">สูง</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicBodyFat?.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {data?.healthInfo?.bodyFat}%
                              </p>
                              <p>{data?.mechanicBodyFat?.label}</p>
                            </div>
                          </div>
                        )}
                        {data?.mechanicVisceralFat?.percentTag == 0 ||
                        data?.mechanicVisceralFat?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym4">ไขมันในช่องท้อง</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/visceralfat", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>

                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">ปกติ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color4"></div>
                                  <p className="tColor4">ค่อนข้างสูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color5"></div>
                                  <p className="tColor5">สูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">สูงมาก</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicVisceralFat?.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {data?.healthInfo?.visceralFat}
                              </p>
                              <p>{data?.mechanicVisceralFat?.label}</p>
                            </div>
                          </div>
                        )}
                        {data?.mechanicMuscleMass?.percentTag == 0 ||
                        data?.mechanicMuscleMass?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym5">มวลกล้ามเนื้อ</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/muscle", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>
                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color2"></div>
                                  <p className="tColor2">ค่อนข้างน้อย</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">เหมาะสม</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color4"></div>
                                  <p className="tColor4">ค่อนข้างสูง</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">สูง</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicMuscleMass?.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {data?.healthInfo?.muscleMass}%
                              </p>
                              <p>{data?.mechanicMuscleMass?.label}</p>
                            </div>
                          </div>
                        )}
                        {data?.mechanicBoneMass?.percentTag == 0 ||
                        data?.mechanicBoneMass?.percentTag == null ? (
                          <></>
                        ) : (
                          <div className="bCal">
                            <div className="tHeadContent">
                              <p className="sym6">มวลกระดูก</p>
                              <a
                                onClick={() =>
                                  navigate("/detail/bone", {
                                    state: { id: id },
                                  })
                                }
                                className="symArrow"
                              ></a>
                            </div>
                            <div className="tGraph">
                              <div className="colorGauge">
                                <div className="colorBlock">
                                  <div className="color6"></div>
                                  <p className="tColor6">ต่ำ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color3"></div>
                                  <p className="tColor3">ปกติ</p>
                                </div>
                                <div className="colorBlock">
                                  <div className="color2"></div>
                                  <p className="tColor2">ดีมาก</p>
                                </div>
                              </div>
                              <div
                                className="tPoint"
                                style={{
                                  left: `${data?.mechanicBoneMass?.percentTag}%`,
                                }}
                              ></div>
                            </div>
                            <div className="tCal">
                              <p className="tBold">
                                {data?.healthInfo?.boneMass} กก.
                              </p>
                              <p>{data?.mechanicBoneMass?.label}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={() => navigate("/healthservice-catagory")}
                >
                  <p>ย้อนกลับ</p>
                </button>
                {/* <!-- <button className="btn" onclick="">ยืนยันข้อมูล</button> --> */}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default HealthServiceHistory;
