import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../../features/RegisterSlice";
import { setProfile } from "../../../../features/ProfileSlice";
import {
  OtpRequest,
  OtpVerify,
  updateCustomer,
} from "../../../../services/Api/Module/Customer";

import { convertMobileFormat } from "../../../../services/helper";

import PageTitleGuest from "../../../../layouts/inc/PageTitleGuest";

import ModalContext from "../../../../contexts/ModalContext";

// import useTranslations from "../../../../i18n/useTranslations";

import img_icoOtp from "../../../../assets/img/ci/icon-register/ico-otp.svg";

import ModalEditProfileSuccess from "../../../../components/modal/account/profile/edit/ModalEditProfileSuccess";

const EditProfileOtp = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  /* End Handle goto page */

  /* Handle OTP input */
  const [otp, setOtp] = useState("");
  // const [refOtp, setRefOtp] = useState("");

  async function handlePasteOtp(e) {
    // console.log("handlePasteOtp e >>", e.clipboardData.getData("text"));
    const textClipboard = e.clipboardData.getData("text");
    let maxLength = 6;

    if (textClipboard) {
      const smsCodeElements = document.querySelectorAll(".smsCode");

      for (let i = 0; i < maxLength; i++) {
        if (textClipboard[i]) {
          smsCodeElements[i].value = textClipboard[i];
        }
      }
      if (textClipboard.length) {
        if (textClipboard.length < maxLength) {
          smsCodeElements[textClipboard.length - 1].focus();
        } else {
          smsCodeElements[maxLength - 1].focus();
        }
      }
    }
  }
  async function handleInputOtp(e) {
    // console.log("handleInputOtp!!", e);

    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
      target.value = "";
      return;
    }

    if (val != "") {
      const next = target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }

    setValueOtp();
  }
  function handleKeyupOtp(e) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }

  function setValueOtp() {
    let otpValue = "";
    const smsCodeElements = document.querySelectorAll(".smsCode");
    for (const smsCodeElement of smsCodeElements) {
      // console.log("smsCodeElement.value >>", smsCodeElement.value);
      otpValue += smsCodeElement.value;
    }
    setOtp(otpValue);
  }
  /* End Handle OTP input */

  /* Modal edit profile success */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCloseModal = () => {
    setIsModalShow(false);

    setTimeout(() => navigate("/profile"), 200);
  };
  /* End Modal edit profile success */

  /* Handle click submit form */
  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(stateProfile);
    // console.log(otp);

    document
      .getElementsByClassName("btn-submit-otp")[0]
      .setAttribute("disabled", "");

    const payloadOtpVerify = {
      token: otpToken,
      pin: otp,
    };

    postOtpVerify(payloadOtpVerify).then((_rsDataOtpVerify) => {
      // console.log(_rsDataOtpVerify);

      if (
        _rsDataOtpVerify.result !== null &&
        _rsDataOtpVerify.result.status === "success"
      ) {
        let payload = {
          id: stateProfile.id,
          custCode: stateProfile.custCode,
          ...stateProfile.updData,
        };
        // console.log(payload);

        postUpdateCustomer(payload).then((_rsDataUpdateCustomer) => {
          // console.log(_rsDataUpdateCustomer);

          if (_rsDataUpdateCustomer.status === true) {
            setDataProfile({
              ...stateProfile.dataUser,
              ...stateProfile.updData,
            });

            setIsModalShow(true);
          }
        });
      } else {
        console.warn(_rsDataOtpVerify);
      }
    });
  };

  const postOtpVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpVerify({ data: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpVerify resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "";

        if (data.resultCode === 40400) {
          switch (data.resultError.code) {
            case "otp_invalid":
              _title = `รหัสผิดพลาด กรุณากรอกใหม่`;
              break;

            case "otp_expired":
              _title = `รหัสหมดอายุ กรุณาส่งรหัสใหม่อีกครั้ง`;
              break;

            default:
              // _title = `${t.otpPage.modalAlertMsg.default.title}`;
              // _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

              // modalAlert.info({
              //   open: true,
              //   title: _title,
              //   subTitle: _subTitle,
              //   content: "",
              // });
              break;
          }

          if (
            data.resultError.code === "otp_invalid" ||
            data.resultError.code === "otp_expired"
          ) {
            const _form = document.querySelectorAll("#pEditOTP .bOtp .bFrom");
            _form[0].classList.add("error");

            const _txtError = document.querySelectorAll(
              "#pEditOTP .bOtp .bFrom .tError"
            );
            _txtError[0].textContent = _title;
          }
        }

        document
          .getElementsByClassName("btn-submit-otp")[0]
          .removeAttribute("disabled");
      }
    } catch (e) {
      console.log("ERR postOtpVerify >> ", e);
    }

    return _return;
  };

  const postUpdateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateCustomer({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postUpdateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR postUpdateCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const postOtpRequest = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpRequest resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postOtpRequest >> ", e);
    }

    return _return;
  };

  const [otpToken, setOtpToken] = useState("");
  const [otpRefno, setOtpRefno] = useState("");

  const handleOtpRequest = (mobileNo) => {
    const payloadOtpRequest = {
      msisdn: mobileNo,
    };

    postOtpRequest(payloadOtpRequest).then((_rsDataOtpRequest) => {
      // console.log(_rsDataOtpRequest);

      if (
        _rsDataOtpRequest.result !== null &&
        Object.keys(_rsDataOtpRequest.result).length !== 0
      ) {
        if (
          "status" in _rsDataOtpRequest.result &&
          _rsDataOtpRequest.result.status === "success"
        ) {
          setOtpToken(_rsDataOtpRequest.result.token);
          setOtpRefno(_rsDataOtpRequest.result.refno);
        } else {
          console.warn(_rsDataOtpRequest);
        }
      } else {
        console.warn(_rsDataOtpRequest);
      }
    });
  };

  useEffect(() => {
    console.log("useEffect !", stateProfile);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    const { updData } = stateProfile;
    if (Object.keys(updData).length !== 0) {
      if (updData.mobileNo) {
        handleOtpRequest(updData.mobileNo);
      }
    } else {
      navigate("/edit-profile");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageEditOTP">
        <main>
          <form onSubmit={handleSubmit}>
            <div className="wrapContent">
              <section id="pEditOTP">
                <div className="bEditOTP">
                  <div className="tEditOTP">
                    <img src={img_icoOtp} alt="" />
                    <p>กรอกรหัส OTP เพื่อยืนยัน</p>
                  </div>

                  <div className="bOtp">
                    <p>
                      เราเพิ่งส่งรหัส OTP ไปให้ท่านทาง
                      <br />
                      SMS ผ่านเบอร์{" "}
                      {stateProfile.updData.mobileNo &&
                        convertMobileFormat(stateProfile.updData.mobileNo, "-")}
                    </p>
                    <div className="bFrom">
                      <div className="control-group otp">
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onPaste={handlePasteOtp}
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                      </div>
                      <div className="bTextSend">
                        <p>
                          ไม่ได้รับรหัส?{" "}
                          <a
                            onClick={() =>
                              stateProfile.updData.mobileNo
                                ? handleOtpRequest(
                                    stateProfile.updData.mobileNo
                                  )
                                : console.warn(stateProfile.updData.mobileNo)
                            }
                          >
                            ส่งรหัสใหม่อีกครั้ง
                          </a>
                        </p>
                      </div>
                      {/* <p className="tError">รหัสผิดพลาด กรุณากรอกใหม่</p> */}
                      <p className="tError"></p>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button className="btn outline" onClick={() => goBack()}>
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn btnConfirm btn-submit-otp"
                    disabled={otp.length < 4}
                  >
                    ยืนยัน
                  </button>
                </div>
              </section>
            </div>
          </form>

          <ModalEditProfileSuccess
            open={isModalShow}
            onClose={handleCloseModal}
          />
        </main>
      </div>
    </>
  );
};

export default EditProfileOtp;
