import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { checkDupMobileNo } from "../../../services/Api/Module/Customer";

import PageTitleGuest from "../../../layouts/inc/PageTitleGuest";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoRegister from "../../../assets/img/ci/icon-register/ico-register.svg";

const RegisterUser = () => {
  
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  // const stateProfile = useSelector((state) => state.profile);

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("register");

  const PageRoute = {
    home: "/",
    "auth-otp": "/auth-otp",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      birthDate: "",
      gender: "",
      mobileNo: "",
      isConsent: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          handleCheckMobileNoExists(_value);
        } else {
          _invalid = true;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    setDataRegis({ ...data, mobileNo: convertMobileFormat(data.mobileNo) });

    // console.log('onSubmit >>', data);
    goNext("auth-otp");
  };

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* onClick privacy policy save form data temp  */
  const setTempDataRegis = () => {
    const updateData = {
      firstname: watch("firstname"),
      lastname: watch("lastname"),
      birthDate: watch("birthDate"),
      gender: watch("gender"),
      mobileNo: convertMobileFormat(watch("mobileNo")),
      isConsent: watch("isConsent"),
    };
    // console.log("setTempDataRegis >>", updateData);
    setDataRegis(updateData);
  };
  /* End onClick privacy policy save form data temp */

  /* Check mobile nubmer exists real-time on server-side  */
  const handleCheckMobileNoExists = (mobileNo) => {
    // console.log(mobileNo);

    clearErrors("mobileNo");

    getCheckDupMobileNo({
      mobileNo: mobileNo,
    }).then((_rsData) => {
      // console.log(_rsData);
      // if (_rsData.status === true) {
      //
      // } else {
      //
      // }
    });
  };

  const getCheckDupMobileNo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await checkDupMobileNo({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCheckDupMobileNo resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        if (data.resultCode === 40301) {
          setFormIsValid(false);

          setError(
            "mobileNo",
            {
              type: "manual",
              message: `เบอร์โทรศัพท์นี้มีผู้ใช้งานแล้ว`,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          clearErrors("mobileNo");
        }
      }
    } catch (e) {
      console.log("ERR getCheckDupMobileNo >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exists real-time on server-side  */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("firstname", dataUser.firstname);
      setValue("lastname", dataUser.lastname);
      setValue("birthDate", dataUser.birthDate);
      setValue("gender", dataUser.gender);
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("isConsent", dataUser.isConsent);

      let _chkFormIsValid = true;
      Object.entries(dataUser).forEach(([key, val]) => {
        if (val === "" || val === false || val === null) {
          _chkFormIsValid = false;
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }

    handleCheckOnChange();
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegisterNew">
        <main>
          <div className="wrapContent">
            <PageTitleGuest
              step={{
                current: 1,
                max: 2,
              }}
            />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister">
                  <h3>
                    <img src={img_icoRegister} alt="" />
                    กรอกข้อมูลส่วนตัว
                  </h3>
                  <div className="bForm">
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.firstname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ชื่อ</div>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อ`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกชื่อ`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.firstname && (
                          <label htmlFor="" className="tError">
                            {errors.firstname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.lastname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">นามสกุล</div>
                        <Controller
                          name="lastname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกนามสกุล`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกนามสกุล`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.lastname && (
                          <label htmlFor="" className="tError">
                            {errors.lastname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.birthDate ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">วัน/เดือน/ปีเกิด</div>
                        <Controller
                          name="birthDate"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณาเลือกวัน/เดือน/ปีเกิด`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="date"
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.birthDate && (
                          <label htmlFor="" className="tError">
                            {errors.birthDate.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div
                      className={`control-group margine-button ${
                        errors.gender ? "error" : ""
                      }`}
                    >
                      <div className="tTitle">เพศ</div>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: `กรุณาเลือกเพศ`,
                        }}
                        render={({ field }) => (
                          <div className="control-radio">
                            <label className="control control--radio">
                              ชาย
                              <input
                                {...field}
                                type="radio"
                                value="m"
                                checked={field.value === "m"}
                                // onChange={() => {
                                //   setValue("gender", "m");
                                //   // handleCheckOnChange();
                                // }}
                              />
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">
                              หญิง
                              <input
                                {...field}
                                type="radio"
                                value="f"
                                checked={field.value === "f"}
                                // onChange={() => {
                                //   setValue("gender", "f");
                                //   // handleCheckOnChange();
                                // }}
                              />
                              <div className="control__indicator"></div>
                            </label>
                          </div>
                        )}
                      />
                      {errors.gender && (
                        <label htmlFor="" className="tError">
                          {errors.gender.message}
                        </label>
                      )}
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกเบอร์โทรศัพท์`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group pdpa ${
                          errors.isConsent ? "error" : ""
                        }`}
                      >
                        <label className="control control--checkbox">
                          <p>
                            {`ข้าพเจ้าได้อ่าน `}
                            <Link
                              to="/privacy-policy"
                              onClick={() => {
                                setTempDataRegis();
                              }}
                            >
                              นโยบายความเป็นส่วนตัว
                            </Link>
                            {` และยินยอมรับเงื่อนไขของการ เป็นสมาชิก`}
                          </p>
                          <Controller
                            name="isConsent"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `กรุณายอมรับเงื่อนไข`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isConsent")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isConsent && (
                          <label htmlFor="" className="tError">
                            {errors.isConsent.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <p className="tError">Text Error Main Form</p>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("home");
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default RegisterUser;
