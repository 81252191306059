import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setProfile } from "../../../features/ProfileSlice";
import { setSurvey, resetSurvey } from "../../../features/SurveySlice";
import {
  custSurvey,
  custSurveyHistory,
} from "../../../services/Api/Module/Customer";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import MenuMember from "../../../components/menu/MenuMember";

const SurveyList = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateSurvey = useSelector((state) => state.survey);

  const setDataProfile = (newSurvey = 0, newSurveyPoint = 0) => {
    const tmpObj = {
      newSurvey: newSurvey,
      newSurveyPoint: newSurveyPoint,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setDataSurvey = (id, data) => {
    const tmpObj = { id: id, data: data };
    dispatch(setSurvey(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataSurvey = () => {
    dispatch(resetSurvey()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);
  const [newSurvey, setNewSurvey] = useState([]);
  const [surveyHistory, setSurveyHistory] = useState([]);

  const getSurvey = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custSurvey({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSurvey resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getSurvey >> ", e);
    }

    return _return;
  };

  const getSurveyHistory = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custSurveyHistory({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSurveyHistory resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getSurveyHistory >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateSurvey);
    // console.log("useEffect !", Object.getOwnPropertyNames(stateProfile.dataUser).length);

    if (
      stateProfile.id === 0 ||
      Object.getOwnPropertyNames(stateProfile.dataUser).length === 0
    ) {
      navigate("/");
    } else {
      if (stateSurvey.id !== 0) {
        resetDataSurvey();
      }

      getSurvey({
        custId: stateProfile.id,
        lineUid: stateProfile.lineUid,
      }).then((_rsDataSurvey) => {
        // console.log("_rsDataSurvey >>", _rsDataSurvey);

        if (
          _rsDataSurvey.status === true &&
          _rsDataSurvey.result.length !== 0
        ) {
          setNewSurvey(_rsDataSurvey.result.surveyList);

          setDataProfile(
            _rsDataSurvey.result.availableSurvey.toLocaleString(),
            _rsDataSurvey.result.totalPoint.toLocaleString()
          );
        }

        if (isLoading) {
          setIsLoading(false);
        }
      });

      getSurveyHistory({
        custId: stateProfile.id,
        lineUid: stateProfile.lineUid,
      }).then((_rsDataSurvey) => {
        // console.log("_rsDataSurvey >>", _rsDataSurvey);

        if (
          _rsDataSurvey.status === true &&
          _rsDataSurvey.result.length !== 0
        ) {
          setSurveyHistory(_rsDataSurvey.result);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log(newSurvey);
  // }, [newSurvey]);

  // useEffect(() => {
  //   console.log(surveyHistory);
  // }, [surveyHistory]);
  /* End First event render */

  // useEffect(() => {
  //   console.log(stateSurvey);
  // }, [stateSurvey]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageSurvey-All">
        <main>
          <div className="wrapContent">
            <section id="pTiltlePage">
              <div className="bTitle">
                <h1>แบบสอบถาม Health up</h1>
              </div>
            </section>
            <section id="pContentMain">
              {isLoading ? (
                <div style={{ margin: "auto" }}>
                  <p>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                  </p>
                </div>
              ) : (
                <>
                  {newSurvey.length > 0 ? (
                    <div className="bNewSurvey">
                      <h2>ใหม่ล่าสุด!</h2>
                      <div className="bList">
                        {newSurvey.map((item, index) => (
                          <a
                            href="#"
                            onClick={(e) => {
                              setDataSurvey(item.surveyId, item);
                              handleGotoPage(e);
                            }}
                            data-page="survey/form"
                            key={index + 1}
                          >
                            <article>
                              <div className="bTitle">
                                <h3>{item.surveyName}</h3>
                                <p>
                                  ตอบคำถามได้ถึงวันที่{" "}
                                  <span>
                                    {moment(item.endDate).format("DD/MM/YYYY")}
                                  </span>
                                </p>
                              </div>
                              <div className="bPoint">
                                {item.point.toLocaleString()} pt
                              </div>
                            </article>
                          </a>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div class="bNodata"></div>
                  )}
                </>
              )}
              {!isLoading && surveyHistory.length > 0 && (
                <div className="bCompleteSurvey">
                  <h2>เสร็จสิ้นแล้ว</h2>
                  <div className="bList">
                    {surveyHistory.map((item, index) => (
                      <a
                        href="#"
                        onClick={(e) => {
                          setDataSurvey(item.surveyId, item);
                          handleGotoPage(e);
                        }}
                        data-page="survey/history"
                        key={index + 1}
                      >
                        <article>
                          <div className="bTitle">
                            <h3>{item.surveyName}</h3>
                          </div>
                          <div className="bPoint">
                            {item.point.toLocaleString()} pt
                          </div>
                        </article>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </section>

            <MenuMember
              pageActive="survey"
              newSurvey={stateProfile.newSurvey}
              newSurveyPoint={stateProfile.newSurveyPoint}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default SurveyList;
