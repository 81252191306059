import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTransaction } from "../../../../features/TransactionSlice";

const CardBuyTransaction = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataTransaction = (newData) => {
    const tmpObj = { id: newData.id, data: newData };
    dispatch(setTransaction(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto transaction detail page */
  const navigate = useNavigate();

  const handleGotoTransactionDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoTransactionDetail >>", props.dataTransaction);

    setDataTransaction(props.dataTransaction);

    navigate("/buy-transaction-history/detail");
  };
  /* End Handle goto transaction detail page */

  // useEffect(() => {
  //   console.log("props", props);
  // }, []);

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article ref={eleRef} data-year={props.year} data-month={props.month}>
      <div className="bHead">
        <p>หมายเลขคำสั่งซื้อ</p>
        <p>{props.buyNo}</p>
      </div>
      <div className="bDetails">
        <div className="bRow">
          <p>วันที่ :</p>
          <p className="textEnd">{props.buyDateTh} น.</p>
        </div>
        <div className="bRow">
          {/* <p>จำนวนสินค้า :</p>
          <p className="textEnd">
            {props.sumProduct && parseInt(props.sumProduct).toLocaleString()}
          </p> */}
          <p>คะแนนที่ได้รับ :</p>
          <p className="textEnd">
            {(
              parseInt(props.pointBill || 0) +
              parseInt(props.dataTransaction.pointProduct || 0)
            ).toLocaleString()}
          </p>
        </div>
        <div className="bRow price">
          <p>ราคารวมทั้งหมด :</p>
          <p className="textEnd">
            {props.totalPrice && parseInt(props.totalPrice).toLocaleString()}
          </p>
        </div>
      </div>
      <div className="bBtn">
        <a href="#" onClick={handleGotoTransactionDetail}>
          คลิกเพื่อดูรายละเอียด
        </a>
      </div>
    </article>
  );
};

export default CardBuyTransaction;
