import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { searchCustomer } from "../../../services/Api/Module/Customer";

import PageTitleGuest from "../../../layouts/inc/PageTitleGuest";

import ModalContext from "../../../contexts/ModalContext";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoVerify from "../../../assets/img/ci/icon-register/ico-verify.svg";

const AuthUser = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  // const stateProfile = useSelector((state) => state.profile);

  const { modalAlert } = useContext(ModalContext); // import modal

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-user");

  const PageRoute = {
    home: "/",
    "auth-preview": "/auth-preview",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      mobileNo: "",
      custCode: "",
      firstname: "",
    },
  });

  // console.log("isValid: ", isValid);
  /* End Form */

  /* onChange Radio input search type */
  let searchTypeSelected = "phonenumber";
  const [searchType, setSearchType] = useState(searchTypeSelected);

  const changeSearchType = (e) => {
    searchTypeSelected = e.target.value;

    switch (searchType) {
      case "phonenumber":
        resetField("mobileNo");
        break;

      default:
        resetField("custCode");
        break;
    }

    document.getElementsByClassName("input-" + searchType)[0].style.display =
      "none";

    setSearchType(searchTypeSelected);

    document.getElementsByClassName(
      "input-" + searchTypeSelected
    )[0].style.display = "block";
  };

  useEffect(() => {
    // console.log(searchType);
  }, [searchType]);
  /* End onChange Radio input search type */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    if (data) {
      let payload = {
        q: "verify",
      };

      if (typeof data.mobileNo !== "undefined" && data.mobileNo !== "") {
        payload = {
          ...payload,
          mobileNo: convertMobileFormat(data.mobileNo),
        };
      }

      if (typeof data.custCode !== "undefined" && data.custCode !== "") {
        payload = {
          ...payload,
          custCode: data.custCode,
        };
      }

      payload = {
        ...payload,
        firstname: data.firstname,
      };

      // console.log(payload);
      getSearchCustomer(payload).then((_rsData) => {
        // console.log("getSearchCustomer >>>", _rsData);

        if (_rsData.status === true && _rsData.result.length !== 0) {
          let _dataFrom = _rsData.result.dataFrom
              ? _rsData.result.dataFrom
              : null,
            _dataUser = {
              custCode: _rsData.result.custCode
                ? _rsData.result.custCode
                : null,
              firstname: _rsData.result.firstname,
              lastname: _rsData.result.lastname,
              birthDate: convertDateFormat(_rsData.result.birthDate),
              gender: _rsData.result.gender,
              mobileNo: _rsData.result.mobileNo,
              isConsent: false,
            };

          if (_rsData.result.id) {
            _dataUser = {
              ..._dataUser,
              id: _rsData.result.id,
            };
          }

          if (_dataFrom !== null) {
            _dataUser = {
              ..._dataUser,
              custType: _rsData.result.custType,
              branch: _rsData.result.branch,
              currentPoint: _rsData.result.currentPoint
                ? _rsData.result.currentPoint
                : 0,
              totalBuy: _rsData.result.totalBuy ? _rsData.result.totalBuy : 0,
            };
          }

          setDataRegis(_dataUser, _dataFrom);

          goNext("auth-preview");
        } else {
          console.warn(_rsData);
        }
      });
    }
  };

  const setDataRegis = (newData = {}, dataFrom = null) => {
    const tmpObj = { dataUser: newData, dataFrom: dataFrom };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const getSearchCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchCustomer({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCustomer resData >>", resData);

        if (data.body.custType !== "6") {
          let _title = `ขออภัย<br/>คุณไม่ใช่สมาชิก HUG Club`,
            _subTitle = `กรุณาลงทะเบียนสมาชิก`,
            _content = `หากพบปัญหา<br/>กรุณาติดต่อ สาขา Health Up ใกล้เคียง<br/>หรือโทรศัพท์ 027383030 ต่อ 602-603`;

          modalAlert.info({
            open: true,
            title: _title,
            subTitle: _subTitle,
            content: _content,
          });

        } else {
          _return.status = true;
          _return.result = resData;
        }

      } else {
        if (data.resultCode === 40401) {
          let _title = `ไม่พบข้อมูลสมาชิก`,
            _subTitle = `ระบบไม่พบข้อมูลสมาชิกของท่าน<br/>กรุณาลองใหม่อีกครั้ง`,
            _content = `หากพบปัญหา<br/>กรุณาติดต่อ สาขา Health Up ใกล้เคียง<br/>หรือโทรศัพท์ 027383030 ต่อ 602-603`;

          modalAlert.info({
            open: true,
            title: _title,
            subTitle: _subTitle,
            content: _content,
          });
        }
      }
    } catch (e) {
      console.log("ERR getSearchCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("firstname", dataUser.firstname);
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegisterVerify">
        <main>
          <div className="wrapContent">
            <PageTitleGuest
              step={{
                current: 1,
                max: 3,
              }}
            />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister verify">
                  <h3 className="verify">
                    <img src={img_icoVerify} alt="" />
                    ค้นหาทะเบียนสมาชิก
                  </h3>
                  <div className="bForm">
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle">เลือกวิธีการค้นหา</div>
                        <div className="control-group searchtype">
                          <label className="control control--radio">
                            เบอร์โทรศัพท์
                            <input
                              type="radio"
                              name="searchtype"
                              value="phonenumber"
                              checked={searchType === "phonenumber"}
                              onChange={(e) => changeSearchType(e)}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            รหัสบัตรสมาชิก
                            <input
                              type="radio"
                              name="searchtype"
                              value="member"
                              checked={searchType === "member"}
                              onChange={(e) => changeSearchType(e)}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          {/* <label className="tError">กรุณาเลือกประเภทการค้นหา</label> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="bRow member input-member"
                      style={{
                        display: "none",
                      }}
                    >
                      <div
                        className={`control-group ${
                          errors.custCode ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">รหัสบัตรสมาชิก</div>
                        <Controller
                          name="custCode"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              searchType === "member"
                                ? `กรุณากรอกรหัสบัตรสมาชิก`
                                : false,
                          }}
                          disabled={searchType !== "member" ?? true}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกรหัสบัตรสมาชิก`}
                            />
                          )}
                        />
                        {errors.custCode && (
                          <label htmlFor="" className="tError">
                            {errors.custCode.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div
                      className="bRow phonenumber input-phonenumber"
                      style={{
                        display: "block",
                      }}
                    >
                      <div className="control-group">
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              searchType === "phonenumber"
                                ? `กรุณากรอกเบอร์โทรศัพท์`
                                : false,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          disabled={searchType !== "phonenumber" ?? true}
                          render={({
                            field: { name, value, disabled, onChange },
                          }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              disabled={disabled}
                              onChange={onChange}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow firstname">
                      <div
                        className={`control-group ${
                          errors.firstname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ชื่อ</div>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อของคุณ`,
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกเฉพาะชื่อของคุณ เช่น วิชัย`}
                              onInput={Helper.FNFORM.handleCharOnly}
                            />
                          )}
                        />
                        {errors.firstname && (
                          <label htmlFor="" className="tError">
                            {errors.firstname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("home");
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={isValid ? false : true}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthUser;
